import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { exhaustMap, map, take, tap } from 'rxjs/operators';

// import { CustomerService } from '../../customer/customer.service';
import { CustomerModel } from '../../customer/customer.model';
import { AuthService } from 'src/app/authentication/auth.service';
import { environment } from 'src/environments/environment';
import { Subscription } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class DataStorageService {
  baseUrl: string = environment.baseApiUrl;
  userSub: Subscription;

  constructor(
    private http: HttpClient,
    // private customerService: CustomerService,
    private authService: AuthService
  ) {}
  private generateFullUrl(url: string) {
    return `${this.baseUrl + url}`;
  }
  private getAuthHeader(): HttpHeaders {
    const userData = JSON.parse(localStorage.getItem('userData'));
    const header = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${userData._token}`,
    });
    return header;
  }
  private getNoAuthHeader(): HttpHeaders {
    const header = new HttpHeaders({
      'Content-Type': 'application/json',
      Accept: 'application/json',
    });
    return header;
  }
  private getAuthHeaderUpload(): HttpHeaders {
    const userData = JSON.parse(localStorage.getItem('userData'));
    const header = new HttpHeaders({
      Authorization: `Bearer ${userData._token}`,
    });
    return header;
  }
  authGet(url: string) {
    const httpOptions = {
      headers: this.getAuthHeader(),
    };
    return this.http.get<any>(this.generateFullUrl(url), httpOptions);
  }
  authPost(url: string, data: any) {
    const httpOptions = {
      headers: this.getAuthHeader(),
    };
    return this.http.post<any>(this.generateFullUrl(url), data, httpOptions);
  }
  authPostUpload(url: string, data: any) {
    const httpOptions = {
      headers: this.getAuthHeaderUpload(),
    };
    return this.http.post<any>(this.generateFullUrl(url), data, httpOptions);
  }
  authPostNoAuth(url: string, data: any) {
    const httpOptions = {
      headers: this.getNoAuthHeader(),
    };
    return this.http.post<any>(this.generateFullUrl(url), data, httpOptions);
  }
  getUser() {
    const loggedUser = {
      first_name: '',
      last_name: '',
      email: '',
      is_live: false,
    };
    this.userSub = this.authService.user.subscribe((user) => {
      loggedUser.email = user.email;
      (loggedUser.first_name = user.first_name),
        (loggedUser.last_name = user.last_name),
        (loggedUser.is_live = user.is_live);
    });
    return loggedUser;
  }
  // fetchCustomers() {
  //   return this.authService.user.pipe(
  //     take(1),
  //     exhaustMap((user) => {
  //       return this.http.get<CustomerModel[]>('customer/all', {
  //         headers: new HttpHeaders().set(
  //           'Authorization',
  //           'Bearer ' + user.token
  //         ),
  //       });
  //       console.log(user.token);
  //     }),
  //     map((customers) => {
  //       return customers.map((customer) => {
  //         return {
  //           ...customer,
  //         };
  //       });
  //     }),
  //     tap((customers) => {
  //       // this.customerService.setCustomers(customers);
  //     })
  //   );
  // }
}
