import { Component, OnInit } from '@angular/core';
import { DataStorageService } from '../shared/services/data-storage.service';

@Component({
  selector: 'app-resend-otp',
  templateUrl: './resend-otp.component.html',
  styleUrls: ['./resend-otp.component.scss']
})
export class ResendOtpComponent implements OnInit {
  isLoading = false;
  message = ''
  constructor(private dataStorage: DataStorageService) { }

  ngOnInit(): void {
  }
  resendOtp() {
    this.message = '';
    this.isLoading = true;
    this.dataStorage.authGet('admin/resend-otp').subscribe(
      (result)=>{
        if(result)
        {
          this.message = "Check you mail"
          this.isLoading = false;
        }
      }
    )
  }
}
