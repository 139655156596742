<div #sidebar class="sidebar sidebar-right sidebar-animate">
  <div class="p-2">
    <!-- <a href="javascript:;" class="text-right float-right" data-toggle="sidebar-right" data-target=".sidebar-right"><i class="fe fe-x"></i></a>
        <div class="card-options ml-auto">
            <a href="javascript:;" class="sidebar-remove" (click)="onClose()"><i class="fe fe-x"></i></a>
        </div> -->
  </div>
  <div class="tab-menu-heading border-0 card-header">
    <div class="card-title mb-0">Notifications</div>
    <div class="card-options ml-auto mr-3">
      <a href="javascript:;" class="sidebar-remove" (click)="onClose()"
        ><i class="fe fe-x"></i
      ></a>
    </div>
  </div>
  <div class="tabs-menu">
    <nav ngbNav #nav9="ngbNav" class="nav-pills">
      <ng-container ngbNavItem>
        <a ngbNavLink>Profile {{ isAuthenticated }}</a>
        <ng-template ngbNavContent>
          <div class="card-body p-0">
            <div class="header-user text-center mt-4">
              <span class="avatar avatar-xxl brround mx-auto"
                ><img
                  src="../../assets/img/faces/5.jpg"
                  alt="Profile-img"
                  class="avatar avatar-xxl brround"
              /></span>
              <div class="text-center font-weight-semibold user mt-3 h6 mb-0">
                Elizabeth Jane {{ test }}
              </div>
              <span class="text-muted">App Developer</span>
              <div class="card-body">
                <div class="form-group">
                  <label class="form-label text-left">Offline/Online</label>
                  <div #Modal class="form-group">
                    <ng-select
                      placeholder="Online"
                      name="network"
                      class="form-control nice-select mb-4"
                    >
                      <ng-option value="Online" selected>Online</ng-option>
                      <ng-option value="Offline">Offline</ng-option>
                    </ng-select>
                  </div>
                </div>
                <div class="form-group mt-3">
                  <label class="form-label text-left">Website</label>
                  <div #Modal class="form-group">
                    <ng-select
                      placeholder="Spruko.com"
                      name="Website"
                      class="form-control nice-select mb-4"
                    >
                      <ng-option value="Spruko.com" selected
                        >Spruko.com</ng-option
                      >
                      <ng-option value="sprukosoft.com"
                        >sprukosoft.com</ng-option
                      >
                      <ng-option value="sprukotechnologies.com"
                        >sprukotechnologies.com</ng-option
                      >
                      <ng-option value="sprukoinfo.com"
                        >sprukoinfo.com</ng-option
                      >
                      <ng-option value="sprukotech.com"
                        >sprukotech.com</ng-option
                      >
                    </ng-select>
                  </div>
                </div>
              </div>
            </div>
            <a class="dropdown-item border-top" href="javascript:;">
              <i class="dropdown-icon fe fe-edit mr-2"></i> Edit Profile
            </a>
            <a class="dropdown-item border-top" href="javascript:;">
              <i class="dropdown-icon fe fe-user mr-2"></i> Spruko technologies
            </a>
            <a class="dropdown-item border-top" href="javascript:;">
              <i class="dropdown-icon fe fe-unlock mr-2"></i> Add Another
              Account
            </a>
            <a class="dropdown-item border-top" href="javascript:;">
              <i class="dropdown-icon fe fe-mail mr-2"></i> Message
            </a>
            <a class="dropdown-item border-top" href="javascript:;">
              <i class="dropdown-icon fe fe-help-circle mr-2"></i> Need Help?
            </a>
            <div class="card-body border-top">
              <h6>Followers</h6>
              <div class="row mt-4">
                <div class="col-12">
                  <div class="followers">
                    <a
                      href="javascript:;"
                      class="avatar avatar-md cover-image m-1"
                    >
                      <img
                        class="brround"
                        src="../../assets/img/faces/3.jpg"
                        alt=""
                      />
                      <span class="avatar-status bg-green"></span>
                    </a>
                    <a
                      href="javascript:;"
                      class="avatar avatar-md cover-image m-1"
                    >
                      <img
                        class="brround"
                        src="../../assets/img/faces/6.jpg"
                        alt=""
                      />
                      <span class="avatar-status bg-red"></span>
                    </a>
                    <a
                      href="javascript:;"
                      class="avatar avatar-md cover-image m-1"
                    >
                      <img
                        class="brround"
                        src="../../assets/img/faces/3.jpg"
                        alt=""
                      />
                      <span class="avatar-status bg-warning"></span>
                    </a>
                    <a
                      href="javascript:;"
                      class="avatar avatar-md cover-image m-1"
                    >
                      <img
                        class="brround"
                        src="../../assets/img/faces/4.jpg"
                        alt=""
                      />
                      <span class="avatar-status bg-green"></span>
                    </a>
                    <a
                      href="javascript:;"
                      class="avatar avatar-md cover-image m-1"
                    >
                      <img
                        class="brround"
                        src="../../assets/img/faces/9.jpg"
                        alt=""
                      />
                      <span class="avatar-status bg-warning"></span>
                    </a>
                    <a
                      href="javascript:;"
                      class="avatar brround avatar-md cover-image m-1 follwers"
                      >+34</a
                    >
                  </div>
                </div>
              </div>
            </div>
            <div class="card-body border-top border-bottom">
              <div class="row">
                <div class="col-4 text-center">
                  <a class="" href="javascript:;"
                    ><i
                      class="dropdown-icon mdi mdi-message-outline fs-20 m-0 leading-tight"
                    ></i
                  ></a>
                  <div>Inbox</div>
                </div>
                <div class="col-4 text-center">
                  <a class="" href="javascript:;"
                    ><i
                      class="dropdown-icon mdi mdi-tune fs-20 m-0 leading-tight"
                    ></i
                  ></a>
                  <div>
                    <a (click)="fetchCustomer()" style="cursor: pointer"
                      >Settings</a
                    >
                  </div>
                </div>
                <div class="col-4 text-center">
                  <a class="" href="javascript:;"
                    ><i
                      class="dropdown-icon mdi mdi-logout-variant fs-20 m-0 leading-tight"
                    ></i
                  ></a>
                  <div>
                    <a (click)="onLogOut()" style="cursor: pointer">Sign out</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-template>
      </ng-container>
      <ng-container ngbNavItem>
        <a ngbNavLink>Friends</a>
        <ng-template ngbNavContent>
          <div class="chat">
            <div class="contacts_card">
              <div class="input-group mb-0 p-3">
                <input
                  type="text"
                  placeholder="Search..."
                  class="form-control search"
                />
                <div class="input-group-prepend mr-0">
                  <span class="input-group-text search_btn btn-primary"
                    ><i class="fa fa-search text-white"></i
                  ></span>
                </div>
              </div>
              <ul class="contacts mb-0">
                <li class="active">
                  <div class="d-flex bd-highlight w-100">
                    <div class="img_cont">
                      <img
                        src="../../assets/img/faces/12.jpg"
                        class="rounded-circle user_img"
                        alt="img"
                      />
                      <span class="online_icon"></span>
                    </div>
                    <div class="user_info">
                      <h5 class="mt-1 mb-1">Maryam Naz</h5>
                      <small class="text-muted">is online</small>
                    </div>
                    <div class="float-right text-right ml-auto mt-auto mb-auto">
                      <small>01-02-2019</small>
                    </div>
                  </div>
                </li>
                <li>
                  <div class="d-flex bd-highlight w-100">
                    <div class="img_cont">
                      <img
                        src="../../assets/img/faces/2.jpg"
                        class="rounded-circle user_img"
                        alt="img"
                      />
                      <span class="online_icon"></span>
                    </div>
                    <div class="user_info">
                      <h5 class="mt-1 mb-1">Sahar Darya</h5>
                      <small class="text-muted">left 7 mins ago</small>
                    </div>
                    <div class="float-right text-right ml-auto mt-auto mb-auto">
                      <small>01-02-2019</small>
                    </div>
                  </div>
                </li>
                <li>
                  <div class="d-flex bd-highlight w-100">
                    <div class="img_cont">
                      <img
                        src="../../assets/img/faces/5.jpg"
                        class="rounded-circle user_img"
                        alt="img"
                      />
                      <span class="online_icon"></span>
                    </div>
                    <div class="user_info">
                      <h5 class="mt-1 mb-1">Maryam Naz</h5>
                      <small class="text-muted">online</small>
                    </div>
                    <div class="float-right text-right ml-auto mt-auto mb-auto">
                      <small>01-02-2019</small>
                    </div>
                  </div>
                </li>
                <li>
                  <div class="d-flex bd-highlight w-100">
                    <div class="img_cont">
                      <img
                        src="../../assets/img/faces/7.jpg"
                        class="rounded-circle user_img"
                        alt="img"
                      />
                      <span class="online_icon"></span>
                    </div>
                    <div class="user_info">
                      <h5 class="mt-1 mb-1">Yolduz Rafi</h5>
                      <small class="text-muted">online</small>
                    </div>
                    <div class="float-right text-right ml-auto mt-auto mb-auto">
                      <small>02-02-2019</small>
                    </div>
                  </div>
                </li>
                <li>
                  <div class="d-flex bd-highlight w-100">
                    <div class="img_cont">
                      <img
                        src="../../assets/img/faces/8.jpg"
                        class="rounded-circle user_img"
                        alt="img"
                      />
                      <span class="online_icon"></span>
                    </div>
                    <div class="user_info">
                      <h5 class="mt-1 mb-1">Nargis Hawa</h5>
                      <small class="text-muted">30 mins ago</small>
                    </div>
                    <div class="float-right text-right ml-auto mt-auto mb-auto">
                      <small>02-02-2019</small>
                    </div>
                  </div>
                </li>
                <li>
                  <div class="d-flex bd-highlight w-100">
                    <div class="img_cont">
                      <img
                        src="../../assets/img/faces/3.jpg"
                        class="rounded-circle user_img"
                        alt="img"
                      />
                      <span class="online_icon"></span>
                    </div>
                    <div class="user_info">
                      <h5 class="mt-1 mb-1">Khadija Mehr</h5>
                      <small class="text-muted">50 mins ago</small>
                    </div>
                    <div class="float-right text-right ml-auto mt-auto mb-auto">
                      <small>03-02-2019</small>
                    </div>
                  </div>
                </li>
                <li>
                  <div class="d-flex bd-highlight w-100">
                    <div class="img_cont">
                      <img
                        src="../../assets/img/faces/14.jpg"
                        class="rounded-circle user_img"
                        alt="img"
                      />
                      <span class="online_icon"></span>
                    </div>
                    <div class="user_info">
                      <h5 class="mt-1 mb-1">Petey Cruiser</h5>
                      <small class="text-muted">1hr ago</small>
                    </div>
                    <div class="float-right text-right ml-auto mt-auto mb-auto">
                      <small>03-02-2019</small>
                    </div>
                  </div>
                </li>
                <li>
                  <div class="d-flex bd-highlight w-100">
                    <div class="img_cont">
                      <img
                        src="../../assets/img/faces/11.jpg"
                        class="rounded-circle user_img"
                        alt="img"
                      />
                    </div>
                    <div class="user_info">
                      <h5 class="mt-1 mb-1">Khadija Mehr</h5>
                      <small class="text-muted">2hr ago</small>
                    </div>
                    <div class="float-right text-right ml-auto mt-auto mb-auto">
                      <small>03-02-2019</small>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </ng-template>
      </ng-container>
      <ng-container ngbNavItem>
        <a ngbNavLink>Activity</a>
        <ng-template ngbNavContent>
          <div class="list d-flex align-items-center border-bottom p-3 mt-3">
            <div class="">
              <span class="avatar bg-primary brround avatar-md">CH</span>
            </div>
            <div class="wrapper w-100 ml-3">
              <p class="mb-0 d-flex">
                <b>New Websites is Created</b>
              </p>
              <div class="d-flex justify-content-between align-items-center">
                <div class="d-flex align-items-center">
                  <i class="mdi mdi-clock text-muted mr-1"></i>
                  <small class="text-muted ml-auto">30 mins ago</small>
                  <p class="mb-0"></p>
                </div>
              </div>
            </div>
          </div>
          <div class="list d-flex align-items-center border-bottom p-3">
            <div class="">
              <span class="avatar bg-danger brround avatar-md">N</span>
            </div>
            <div class="wrapper w-100 ml-3">
              <p class="mb-0 d-flex">
                <b>Prepare For the Next Project</b>
              </p>
              <div class="d-flex justify-content-between align-items-center">
                <div class="d-flex align-items-center">
                  <i class="mdi mdi-clock text-muted mr-1"></i>
                  <small class="text-muted ml-auto">2 hours ago</small>
                  <p class="mb-0"></p>
                </div>
              </div>
            </div>
          </div>
          <div class="list d-flex align-items-center border-bottom p-3">
            <div class="">
              <span class="avatar bg-info brround avatar-md">S</span>
            </div>
            <div class="wrapper w-100 ml-3">
              <p class="mb-0 d-flex">
                <b>Decide the live Discussion Time</b>
              </p>
              <div class="d-flex justify-content-between align-items-center">
                <div class="d-flex align-items-center">
                  <i class="mdi mdi-clock text-muted mr-1"></i>
                  <small class="text-muted ml-auto">3 hours ago</small>
                  <p class="mb-0"></p>
                </div>
              </div>
            </div>
          </div>
          <div class="list d-flex align-items-center border-bottom p-3">
            <div class="">
              <span class="avatar bg-warning brround avatar-md">K</span>
            </div>
            <div class="wrapper w-100 ml-3">
              <p class="mb-0 d-flex">
                <b>Team Review meeting</b>
              </p>
              <div class="d-flex justify-content-between align-items-center">
                <div class="d-flex align-items-center">
                  <i class="mdi mdi-clock text-muted mr-1"></i>
                  <small class="text-muted ml-auto">4 hours ago</small>
                  <p class="mb-0"></p>
                </div>
              </div>
            </div>
          </div>
          <div class="list d-flex align-items-center border-bottom p-3">
            <div class="">
              <span class="avatar bg-success brround avatar-md">R</span>
            </div>
            <div class="wrapper w-100 ml-3">
              <p class="mb-0 d-flex">
                <b>Prepare for Presentation</b>
              </p>
              <div class="d-flex justify-content-between align-items-center">
                <div class="d-flex align-items-center">
                  <i class="mdi mdi-clock text-muted mr-1"></i>
                  <small class="text-muted ml-auto">1 days ago</small>
                  <p class="mb-0"></p>
                </div>
              </div>
            </div>
          </div>
          <div class="list d-flex align-items-center border-bottom p-3">
            <div class="">
              <span class="avatar bg-pink brround avatar-md">MS</span>
            </div>
            <div class="wrapper w-100 ml-3">
              <p class="mb-0 d-flex">
                <b>Prepare for Presentation</b>
              </p>
              <div class="d-flex justify-content-between align-items-center">
                <div class="d-flex align-items-center">
                  <i class="mdi mdi-clock text-muted mr-1"></i>
                  <small class="text-muted ml-auto">1 days ago</small>
                  <p class="mb-0"></p>
                </div>
              </div>
            </div>
          </div>
          <div class="list d-flex align-items-center border-bottom p-3">
            <div class="">
              <span class="avatar bg-purple brround avatar-md">L</span>
            </div>
            <div class="wrapper w-100 ml-3">
              <p class="mb-0 d-flex">
                <b>Prepare for Presentation</b>
              </p>
              <div class="d-flex justify-content-between align-items-center">
                <div class="d-flex align-items-center">
                  <i class="mdi mdi-clock text-muted mr-1"></i>
                  <small class="text-muted ml-auto">45 mintues ago</small>
                  <p class="mb-0"></p>
                </div>
              </div>
            </div>
          </div>
          <div class="list d-flex align-items-center border-bottom p-3">
            <div class="">
              <span class="avatar bg-primary brround avatar-md">CH</span>
            </div>
            <div class="wrapper w-100 ml-3">
              <p class="mb-0 d-flex">
                <b>New Websites is Created</b>
              </p>
              <div class="d-flex justify-content-between align-items-center">
                <div class="d-flex align-items-center">
                  <i class="mdi mdi-clock text-muted mr-1"></i>
                  <small class="text-muted ml-auto">30 mins ago</small>
                  <p class="mb-0"></p>
                </div>
              </div>
            </div>
          </div>
          <div class="list d-flex align-items-center p-3">
            <div class="">
              <span class="avatar bg-blue brround avatar-md">U</span>
            </div>
            <div class="wrapper w-100 ml-3">
              <p class="mb-0 d-flex">
                <b>Prepare for Presentation</b>
              </p>
              <div class="d-flex justify-content-between align-items-center">
                <div class="d-flex align-items-center">
                  <i class="mdi mdi-clock text-muted mr-1"></i>
                  <small class="text-muted ml-auto">2 days ago</small>
                  <p class="mb-0"></p>
                </div>
              </div>
            </div>
          </div>
        </ng-template>
      </ng-container>
      <ng-container ngbNavItem>
        <a ngbNavLink>Todo</a>
        <ng-template ngbNavContent>
          <div class="mt-3">
            <div class="d-flex p-3">
              <label class="custom-control custom-checkbox mb-0">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  name="example-checkbox1"
                  value="option1"
                  checked=""
                />
                <span class="custom-control-label">Do Even More..</span>
              </label>
              <span class="ml-auto">
                <a href="javascript:;"
                  ><i
                    class="si si-pencil text-primary mr-2"
                    data-toggle="tooltip"
                    title=""
                    data-placement="top"
                    data-original-title="Edit"
                  ></i
                ></a>
                <a href="javascript:;"
                  ><i
                    class="si si-trash text-danger mr-2"
                    data-toggle="tooltip"
                    title=""
                    data-placement="top"
                    data-original-title="Delete"
                  ></i
                ></a>
              </span>
            </div>
            <div class="d-flex p-3 border-top">
              <label class="custom-control custom-checkbox mb-0">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  name="example-checkbox2"
                  value="option2"
                  checked=""
                />
                <span class="custom-control-label">Find an idea.</span>
              </label>
              <span class="ml-auto">
                <a href="javascript:;"
                  ><i
                    class="si si-pencil text-primary mr-2"
                    data-toggle="tooltip"
                    title=""
                    data-placement="top"
                    data-original-title="Edit"
                  ></i
                ></a>
                <a href="javascript:;"
                  ><i
                    class="si si-trash text-danger mr-2"
                    data-toggle="tooltip"
                    title=""
                    data-placement="top"
                    data-original-title="Delete"
                  ></i
                ></a>
              </span>
            </div>
            <div class="d-flex p-3 border-top">
              <label class="custom-control custom-checkbox mb-0">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  name="example-checkbox3"
                  value="option3"
                  checked=""
                />
                <span class="custom-control-label">Hangout with friends</span>
              </label>
              <span class="ml-auto">
                <a href="javascript:;"
                  ><i
                    class="si si-pencil text-primary mr-2"
                    data-toggle="tooltip"
                    title=""
                    data-placement="top"
                    data-original-title="Edit"
                  ></i
                ></a>
                <a href="javascript:;"
                  ><i
                    class="si si-trash text-danger mr-2"
                    data-toggle="tooltip"
                    title=""
                    data-placement="top"
                    data-original-title="Delete"
                  ></i
                ></a>
              </span>
            </div>
            <div class="d-flex p-3 border-top">
              <label class="custom-control custom-checkbox mb-0">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  name="example-checkbox4"
                  value="option4"
                />
                <span class="custom-control-label">Do Something else</span>
              </label>
              <span class="ml-auto">
                <a href="javascript:;"
                  ><i
                    class="si si-pencil text-primary mr-2"
                    data-toggle="tooltip"
                    title=""
                    data-placement="top"
                    data-original-title="Edit"
                  ></i
                ></a>
                <a href="javascript:;"
                  ><i
                    class="si si-trash text-danger mr-2"
                    data-toggle="tooltip"
                    title=""
                    data-placement="top"
                    data-original-title="Delete"
                  ></i
                ></a>
              </span>
            </div>
            <div class="d-flex p-3 border-top">
              <label class="custom-control custom-checkbox mb-0">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  name="example-checkbox5"
                  value="option5"
                />
                <span class="custom-control-label"
                  >Eat healthy, Eat Fresh..</span
                >
              </label>
              <span class="ml-auto">
                <a href="javascript:;"
                  ><i
                    class="si si-pencil text-primary mr-2"
                    data-toggle="tooltip"
                    title=""
                    data-placement="top"
                    data-original-title="Edit"
                  ></i
                ></a>
                <a href="javascript:;"
                  ><i
                    class="si si-trash text-danger mr-2"
                    data-toggle="tooltip"
                    title=""
                    data-placement="top"
                    data-original-title="Delete"
                  ></i
                ></a>
              </span>
            </div>
            <div class="d-flex p-3 border-top">
              <label class="custom-control custom-checkbox mb-0">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  name="example-checkbox6"
                  value="option6"
                  checked=""
                />
                <span class="custom-control-label">Finsh something more..</span>
              </label>
              <span class="ml-auto">
                <a href="javascript:;"
                  ><i
                    class="si si-pencil text-primary mr-2"
                    data-toggle="tooltip"
                    title=""
                    data-placement="top"
                    data-original-title="Edit"
                  ></i
                ></a>
                <a href="javascript:;"
                  ><i
                    class="si si-trash text-danger mr-2"
                    data-toggle="tooltip"
                    title=""
                    data-placement="top"
                    data-original-title="Delete"
                  ></i
                ></a>
              </span>
            </div>
            <div class="d-flex p-3 border-top">
              <label class="custom-control custom-checkbox mb-0">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  name="example-checkbox7"
                  value="option7"
                  checked=""
                />
                <span class="custom-control-label">Do something more</span>
              </label>
              <span class="ml-auto">
                <a href="javascript:;"
                  ><i
                    class="si si-pencil text-primary mr-2"
                    data-toggle="tooltip"
                    title=""
                    data-placement="top"
                    data-original-title="Edit"
                  ></i
                ></a>
                <a href="javascript:;"
                  ><i
                    class="si si-trash text-danger mr-2"
                    data-toggle="tooltip"
                    title=""
                    data-placement="top"
                    data-original-title="Delete"
                  ></i
                ></a>
              </span>
            </div>
            <div class="d-flex p-3 border-top">
              <label class="custom-control custom-checkbox mb-0">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  name="example-checkbox8"
                  value="option8"
                />
                <span class="custom-control-label">Updated more files</span>
              </label>
              <span class="ml-auto">
                <a href="javascript:;"
                  ><i
                    class="si si-pencil text-primary mr-2"
                    data-toggle="tooltip"
                    title=""
                    data-placement="top"
                    data-original-title="Edit"
                  ></i
                ></a>
                <a href="javascript:;"
                  ><i
                    class="si si-trash text-danger mr-2"
                    data-toggle="tooltip"
                    title=""
                    data-placement="top"
                    data-original-title="Delete"
                  ></i
                ></a>
              </span>
            </div>
            <div class="d-flex p-3 border-top">
              <label class="custom-control custom-checkbox mb-0">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  name="example-checkbox9"
                  value="option9"
                />
                <span class="custom-control-label">System updated</span>
              </label>
              <span class="ml-auto">
                <a href="javascript:;"
                  ><i
                    class="si si-pencil text-primary mr-2"
                    data-toggle="tooltip"
                    title=""
                    data-placement="top"
                    data-original-title="Edit"
                  ></i
                ></a>
                <a href="javascript:;"
                  ><i
                    class="si si-trash text-danger mr-2"
                    data-toggle="tooltip"
                    title=""
                    data-placement="top"
                    data-original-title="Delete"
                  ></i
                ></a>
              </span>
            </div>
            <div class="d-flex p-3 border-top border-bottom">
              <label class="custom-control custom-checkbox mb-0">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  name="example-checkbox10"
                  value="option10"
                />
                <span class="custom-control-label">Settings Changings...</span>
              </label>
              <span class="ml-auto">
                <a href="javascript:;"
                  ><i
                    class="si si-pencil text-primary mr-2"
                    data-toggle="tooltip"
                    title=""
                    data-placement="top"
                    data-original-title="Edit"
                  ></i
                ></a>
                <a href="javascript:;"
                  ><i
                    class="si si-trash text-danger mr-2"
                    data-toggle="tooltip"
                    title=""
                    data-placement="top"
                    data-original-title="Delete"
                  ></i
                ></a>
              </span>
            </div>
            <div class="text-center pt-5 mb-4">
              <a href="javascript:;" class="btn btn-primary">Add more</a>
            </div>
          </div>
        </ng-template>
      </ng-container>
    </nav>
  </div>
  <div class="panel-body tabs-menu-body side-tab-body p-0 border-0">
    <div class="card-body tab-content">
      <div class="tab-pane active show">
        <div [ngbNavOutlet]="nav9"></div>
      </div>
    </div>
  </div>
</div>
<!-- Right-sidebar-closed -->
