import { BrowserModule } from '@angular/platform-browser';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginPageComponent } from './authentication/login-page/login-page.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from './shared/shared.module';
import { RouterModule } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SignUpComponent } from './authentication/sign-up/sign-up.component';
import { HttpClientModule } from '@angular/common/http';
import { TransactionComponent } from './transaction/transaction.component';
import { AccountComponent } from './account/account.component';
import { HomeComponent } from './home-pages/home/home.component'; // Angular CLI environment
import { NotificationSidebarComponent } from './shared/components/notification-sidebar/notification-sidebar.component';
import { DashboardModule } from './components/dashboard/dashboard.module';

// NEw Components
import { CommonModule } from '@angular/common';

import { NgSelectModule } from '@ng-select/ng-select';
import { HighlightModule, HIGHLIGHT_OPTIONS } from 'ngx-highlightjs';
import { ClipboardModule } from 'ngx-clipboard';
import { TradeComponent } from './components/trade/trade.component';
import { VerifyLoginComponent } from './authentication/verify-login/verify-login.component';
import { ResendOtpComponent } from './resend-otp/resend-otp.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginPageComponent,
    SignUpComponent,
    TransactionComponent,
    AccountComponent,
    HomeComponent,
    TradeComponent,
    VerifyLoginComponent,
    ResendOtpComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    RouterModule,
    HttpClientModule,
    CommonModule,
    RouterModule,
    NgbModule,
    NgSelectModule,
    DashboardModule,
  ],
  providers: [
    {
      provide: HIGHLIGHT_OPTIONS,
      useValue: {
        fullLibraryLoader: () => import('highlight.js'),
      },
    },
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
