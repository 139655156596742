import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginPageComponent } from './authentication/login-page/login-page.component';
import { SignUpComponent } from './authentication/sign-up/sign-up.component';
import { HomeComponent } from './home-pages/home/home.component';
import { HoriFullLayoutComponent } from './shared/layouts-horizontal/hori-full-layout/hori-full-layout.component';
import { ContentLayoutComponent } from './shared/layouts/content-layout/content-layout.component';
import { FullLayoutComponent } from './shared/layouts/full-layout/full-layout.component';
import { COUNTENT_ROUTES } from './shared/routes/content.routes';
import { Full_Content_Routes } from './shared/routes/full.routes';
import { AuthGuard } from './authentication/auth.guard';
import { ForgetPasswordComponent } from './components/custom-pages/forget-password/forget-password.component';
import { ResetPasswordComponent } from './components/custom-pages/reset-password/reset-password.component';
const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: 'login', component: LoginPageComponent },
  { path: 'register', component: SignUpComponent },
  { path: 'auth/forgot-password', component: ForgetPasswordComponent },
  { path: 'auth/reset-password', component: ResetPasswordComponent },
  {
    path: '',
    component: FullLayoutComponent,
    children: Full_Content_Routes,
  },
  // { path: '', component: HoriFullLayoutComponent, children: Full_Content_Routes},
  {
    path: '',
    component: ContentLayoutComponent,
    children: COUNTENT_ROUTES,
  },
  { path: '**', redirectTo: 'login' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
