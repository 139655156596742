import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-tables',
  templateUrl: './tables.component.html',
  styleUrls: ['./tables.component.scss'],
})
export class TablesComponent implements OnInit {
  @Input() public DataTable: TableInfo;
  constructor() {}
  displayedColumns: string[];
  columnsToDisplay: string[];
  dataSource = [];

  ngOnInit(): void {
    this.displayedColumns = this.DataTable.header;
    this.columnsToDisplay = this.displayedColumns.slice();
  }
}
export interface TableInfo {
  table_name?: string;
  header: string[];
  data: any[];
  filter?: TableFilter;
  loading?: boolean;
}
export interface TableFilter {
  filter_label: string;
  filter_placeholder: string;
}
