import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContentLayoutComponent } from './layouts/content-layout/content-layout.component';
import { FullLayoutComponent } from './layouts/full-layout/full-layout.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { TapToTopComponent } from './components/tap-to-top/tap-to-top.component';
import { LoaderComponent } from './components/loader/loader.component';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NotificationSidebarComponent } from './components/notification-sidebar/notification-sidebar.component';
import { FullscreenDirective } from './directives/fullscreen.directive';
import { NgSelectModule } from '@ng-select/ng-select';
import { HighlightModule, HIGHLIGHT_OPTIONS } from 'ngx-highlightjs';
import { ClipboardModule } from 'ngx-clipboard';
import { HoriHeaderComponent } from './components-horizontal/hori-header/hori-header.component';
import { HorizontalMenuComponent } from './components-horizontal/horizontal-menu/horizontal-menu.component';
import { HoriFullLayoutComponent } from './layouts-horizontal/hori-full-layout/hori-full-layout.component';
import { TablesComponent } from 'src/app/shared/components/tables/tables.component';
import { LoaderSpinnerComponent } from './components/loader-spinner/loader-spinner.component';

@NgModule({
  declarations: [
    ContentLayoutComponent,
    FullLayoutComponent,
    HeaderComponent,
    FooterComponent,
    SidebarComponent,
    TapToTopComponent,
    LoaderComponent,
    NotificationSidebarComponent,
    FullscreenDirective,
    HoriHeaderComponent,
    HorizontalMenuComponent,
    HoriFullLayoutComponent,
    TablesComponent,
    LoaderSpinnerComponent,
  ],
  imports: [CommonModule, RouterModule, NgbModule, NgSelectModule],
  exports: [
    LoaderComponent,
    ContentLayoutComponent,
    FullLayoutComponent,
    HoriFullLayoutComponent,
    FooterComponent,
    SidebarComponent,
    TapToTopComponent,
    NotificationSidebarComponent,
    FullscreenDirective,
    HoriHeaderComponent,
    HorizontalMenuComponent,
    TablesComponent,
    LoaderSpinnerComponent,
  ],
  providers: [
    {
      provide: HIGHLIGHT_OPTIONS,
      useValue: {
        fullLibraryLoader: () => import('highlight.js'),
      },
    },
  ],
})
export class SharedModule {}
