import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ActivationService {
  private activationMessage = new BehaviorSubject('');
  currentActivationMessage = this.activationMessage.asObservable();

  constructor() {}
  updateActivationMessage(message: string) {
    this.activationMessage.next(message);
  }
}
