import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-full-layout',
  templateUrl: './full-layout.component.html',
  styleUrls: ['./full-layout.component.scss']
})
export class FullLayoutComponent implements OnInit {
  constructor() { }

  ngOnInit(): void {
  }
  mainSidebarOpen: any;
  hoverEffect($event) {
    this.mainSidebarOpen = $event.type == 'mouseover' ? 'sidenav-toggled-open' : '';
  }
  clickonBody(event){
    document.querySelector('.main-body').classList.remove('sidenav-toggled')     
    document.querySelector('.sidebar').classList.remove('sidebar-open')     
  }
}
