export class UserModel {
  constructor(
    public email: string,
    public first_name: string,
    public last_name: string,
    // public company_code: string,
    public company_name: string,
    public company_email: string,
    public id: string,
    private _token: string,
    public is_live: boolean
  ) {}
  get token() {
    return this._token;
  }
}
