<!-- main-header -->
<div class="main-header sticky side-header nav nav-item sticky-pin">
  <div class="container-fluid">
    <div class="main-header-left">
      <div class="app-sidebar__toggle mobile-toggle" data-toggle="sidebar">
        <!-- <a class="open-toggle" href="javascript:;"><i class="header-icons" data-eva="menu-outline"></i></a>
							<a class="close-toggle" href="javascript:;"><i class="header-icons" data-eva="close-outline"></i></a> -->
        <a
          class="open-toggle"
          href="javascript:;"
          (click)="toggleSidebarOpen()"
        >
          <i class="header-icon ti-menu tx-20 bg-transparent"></i>
        </a>
        <a
          class="close-toggle"
          href="javascript:;"
          (click)="toggleSidebarClose()"
        >
          <i class="header-icons fe fe-x"></i>
        </a>
      </div>
      <!-- <div class="main-header-center ml-3 d-sm-none d-md-none d-lg-block">
        <input
          type="search"
          class="form-control"
          placeholder="Search for anything..."
        />
        <button class="btn"><i class="fas fa-search"></i></button>
      </div> -->
    </div>
    <div class="main-header-center">
      <div class="responsive-logo">
        <a routerLink="/dashboard"
          ><img
            src="../../assets/img/brand/iofs-logo.png"
            class="mobile-logo"
            alt="logo"
        /></a>
        <!-- <a routerLink="/dashboard"><img src="../../assets/img/brand/logo-white.png" class="mobile-logo-dark" alt="logo"></a> -->
      </div>
    </div>
    <div class="main-header-right">
      <div class="nav nav-item navbar-nav-right ml-auto">
        <div
          ngbDropdown
          class="navbar-form nav-item my-auto d-none d-md-block d-sm-block d-lg-none"
        >
          <a ngbDropdownToggle class="nav-item">
            <i class="ti-search"></i>
          </a>
          <div ngbDropdownMenu>
            <div class="main-form-search p-2">
              <div class="input-group">
                <div class="input-group-btn search-panel">
                  <ng-select
                    placeholder="All categories"
                    class="form-control p-0"
                  >
                    <ng-option
                      *ngFor="let category of categories"
                      [value]="category.id"
                    >
                      {{ category.name }}
                    </ng-option>
                  </ng-select>
                </div>
                <input
                  type="search"
                  class="form-control"
                  placeholder="Search for anything..."
                />
                <button class="btn search-btn">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="feather feather-search"
                  >
                    <circle cx="11" cy="11" r="8"></circle>
                    <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>

        <div class="nav-item full-screen fullscreen-button">
          <a
            class="new nav-link full-screen-link"
            href="javascript:;"
            appFullscreen
          >
            <i class="fe fe-maximize"></i>
          </a>
        </div>
        <!-- <div ngbDropdown class="nav-item main-header-notification">
          <a ngbDropdownToggle class="new nav-link">
            <i class="ti-bell animated bell-animations"></i
            ><span class="pulse"></span>
          </a>
          <div ngbDropdownMenu class="dropdown-menu-arrow animated fadeInUp">
            <div class="main-dropdown-header d-sm-none">
              <a class="main-header-arrow" href="javascript:;"
                ><i class="icon ion-md-arrow-back"></i
              ></a>
            </div>
            <div class="menu-header-content text-left d-flex">
              <div class="my-auto">
                <h6 class="menu-header-title text-white mb-0">
                  7 New Notifications
                </h6>
              </div>
              <div class="my-auto ml-auto">
                <span class="badge badge-pill badge-warning float-right"
                  >Mark All Read</span
                >
              </div>
            </div>
            <div class="main-notification-list Notification-scroll">
              <a href="javascript:;" class="p-3 d-flex border-bottom">
                <div class="notifyimg bg-success-transparent">
                  <i class="la la-shopping-basket text-success"></i>
                </div>
                <div class="ml-3">
                  <h5 class="notification-label mb-1">New Order Received</h5>
                  <div class="notification-subtext">1 hour ago</div>
                </div>
                <div class="ml-auto">
                  <i class="las la-angle-right text-right text-muted"></i>
                </div>
              </a>
              <a href="javascript:;" class="p-3 d-flex border-bottom">
                <div class="notifyimg bg-danger-transparent">
                  <i class="la la-user-check text-danger"></i>
                </div>
                <div class="ml-3">
                  <h5 class="notification-label mb-1">
                    22 verified registrations
                  </h5>
                  <div class="notification-subtext">2 hour ago</div>
                </div>
                <div class="ml-auto">
                  <i class="las la-angle-right text-right text-muted"></i>
                </div>
              </a>
              <a href="javascript:;" class="p-3 d-flex border-bottom">
                <div class="notifyimg bg-primary-transparent">
                  <i class="la la-check-circle text-primary"></i>
                </div>
                <div class="ml-3">
                  <h5 class="notification-label mb-1">
                    Project has been approved
                  </h5>
                  <div class="notification-subtext">4 hour ago</div>
                </div>
                <div class="ml-auto">
                  <i class="las la-angle-right text-right text-muted"></i>
                </div>
              </a>
              <a href="javascript:;" class="p-3 d-flex border-bottom">
                <div class="notifyimg bg-pink-transparent">
                  <i class="la la-file-alt text-pink"></i>
                </div>
                <div class="ml-3">
                  <h5 class="notification-label mb-1">New files available</h5>
                  <div class="notification-subtext">10 hour ago</div>
                </div>
                <div class="ml-auto">
                  <i class="las la-angle-right text-right text-muted"></i>
                </div>
              </a>
              <a href="javascript:;" class="p-3 d-flex border-bottom">
                <div class="notifyimg bg-warning-transparent">
                  <i class="la la-envelope-open text-warning"></i>
                </div>
                <div class="ml-3">
                  <h5 class="notification-label mb-1">New review received</h5>
                  <div class="notification-subtext">1 day ago</div>
                </div>
                <div class="ml-auto">
                  <i class="las la-angle-right text-right text-muted"></i>
                </div>
              </a>
              <a href="javascript:;" class="p-3 d-flex border-bottom">
                <div class="notifyimg bg-purple-transparent">
                  <i class="la la-gem text-purple"></i>
                </div>
                <div class="ml-3">
                  <h5 class="notification-label mb-1">Updates Available</h5>
                  <div class="notification-subtext">2 days ago</div>
                </div>
                <div class="ml-auto">
                  <i class="las la-angle-right text-right text-muted"></i>
                </div>
              </a>
            </div>
            <div class="text-center dropdown-footer">
              <a href="javascript:;">VIEW ALL</a>
            </div>
          </div>
        </div> -->
        <div ngbDropdown class="main-profile-menu nav nav-item nav-link">
          <a
            ngbDropdownToggle
            class="d-flex profile-user"
            href="javascript:void(0)"
          >
            <img alt="avatar" src="../../assets/img/faces/5.jpg" />
          </a>
          <div ngbDropdownMenu class="dropdown-menu-arrow animated fadeInUp">
            <div class="main-header-profile header-img">
              <div class="main-img-user">
                <img alt="" src="../../assets/img/faces/5.jpg" />
              </div>
              <h6>{{ userData.first_name }}</h6>
              <span>Admin</span>
            </div>
            <a
              ngbDropdownItem
              class="dropdown-item"
              routerLink="/auth/change-password"
            >
              <i class="far fa-edit"></i> Change Password
            </a>
            <a ngbDropdownItem class="" routerLink="/view/settings">
              <i class="fas fa-sliders-h"></i> Account Settings
            </a>
            <a ngbDropdownItem class="" (click)="onLogOut()" routerLink="">
              <i class="fas fa-sign-out-alt"></i> Sign Out
            </a>
          </div>
        </div>
        <button
          class="navbar-toggler navresponsive-toggler d-sm-none"
          type="button"
          type="button"
          (click)="collapse.toggle()"
          [attr.aria-expanded]="!isCollapsed"
        >
          <span class="navbar-toggler-icon fe fe-more-vertical"></span>
        </button>
        <!-- <div class="dropdown main-header-message right-toggle">
          <a
            href="javascript:void(0)"
            class="nav-link"
            (click)="toggleSidebarNotification()"
          >
            <i class="ti-menu tx-20 bg-transparent"></i>
          </a>
        </div> -->
      </div>
    </div>
  </div>
</div>
<!-- /main-header -->

<!-- mobile-header -->
<div class="responsive main-header">
  <div
    class="mb-1 navbar navbar-expand-lg nav nav-item navbar-nav-right responsive-navbar navbar-dark d-sm-none"
  >
    <div
      class="collapse navbar-collapse"
      id="navbarSupportedContent-4"
      #collapse="ngbCollapse"
      [(ngbCollapse)]="isCollapsed"
    >
      <div class="d-flex order-lg-2 ml-auto">
        <div ngbDropdown class="navbar-form nav-item my-auto d-lg-none">
          <a ngbDropdownToggle class="nav-item">
            <i class="ti-search"></i>
          </a>
          <div ngbDropdownMenu>
            <div class="main-form-search p-2">
              <div class="input-group">
                <div class="input-group-btn search-panel">
                  <ng-select
                    placeholder="All categories"
                    class="form-control p-0"
                  >
                    <ng-option
                      *ngFor="let category of categories"
                      [value]="category.id"
                    >
                      {{ category.name }}
                    </ng-option>
                  </ng-select>
                </div>
                <input
                  type="search"
                  class="form-control"
                  placeholder="Search for anything..."
                />
                <button class="btn search-btn">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="feather feather-search"
                  >
                    <circle cx="11" cy="11" r="8"></circle>
                    <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="nav-item full-screen fullscreen-button d-md-flex">
          <a
            class="new nav-link full-screen-link"
            href="javascript:;"
            appFullscreen
          >
            <i class="fe fe-maximize"></i>
          </a>
        </div>
        <div ngbDropdown class="nav-item main-header-message header-contact">
          <a ngbDropdownToggle class="new nav-link">
            <i class="ti-email"></i><span class="pulse-danger"></span>
          </a>
          <div ngbDropdownMenu class="dropdown-menu-arrow animated fadeInUp">
            <div class="main-dropdown-header d-sm-none">
              <a class="main-header-arrow" href="javascript:;"
                ><i class="icon ion-md-arrow-back"></i
              ></a>
            </div>
            <div class="menu-header-content text-left d-flex">
              <div class="my-auto">
                <h6 class="menu-header-title text-white mb-0">
                  5 New Messages
                </h6>
              </div>
              <div class="my-auto ml-auto">
                <span class="badge badge-pill badge-warning float-right"
                  >Mark All Read</span
                >
              </div>
            </div>
            <div class="main-message-list chat-scroll">
              <a href="javascript:;" class="p-3 m-1 d-flex border-bottom">
                <div style="position: relative">
                  <img
                    class="drop-img cover-image"
                    src="../../assets/img/faces/3.jpg"
                  />
                  <span class="avatar-status bg-teal"></span>
                </div>
                <div class="wd-90p">
                  <div class="d-flex">
                    <h5 class="mb-1 name">Paul Molive</h5>
                    <p class="time mb-0 text-right ml-auto float-right">
                      10 min ago
                    </p>
                  </div>
                  <p class="mb-0 desc">I'm sorry but i'm not sure how...</p>
                </div>
              </a>
              <a href="javascript:;" class="p-3 d-flex border-bottom">
                <div style="position: relative">
                  <img
                    class="drop-img cover-image"
                    src="../../assets/img/faces/2.jpg"
                  />
                  <span class="avatar-status bg-teal"></span>
                </div>
                <div class="wd-90p">
                  <div class="d-flex">
                    <h5 class="mb-1 name">Sahar Dary</h5>
                    <p class="time mb-0 text-right ml-auto float-right">
                      13 min ago
                    </p>
                  </div>
                  <p class="mb-0 desc">
                    All set ! Now, time to get to you now......
                  </p>
                </div>
              </a>
              <a href="javascript:;" class="p-3 d-flex border-bottom">
                <div style="position: relative">
                  <img
                    class="drop-img cover-image"
                    src="../../assets/img/faces/9.jpg"
                  />
                  <span class="avatar-status bg-teal"></span>
                </div>
                <div class="wd-90p">
                  <div class="d-flex">
                    <h5 class="mb-1 name">Khadija Mehr</h5>
                    <p class="time mb-0 text-right ml-auto float-right">
                      20 min ago
                    </p>
                  </div>
                  <p class="mb-0 desc">
                    Are you ready to pickup your Delivery...
                  </p>
                </div>
              </a>
              <a href="javascript:;" class="p-3 d-flex border-bottom">
                <div style="position: relative">
                  <img
                    class="drop-img cover-image"
                    src="../../assets/img/faces/12.jpg"
                  />
                  <span class="avatar-status bg-danger"></span>
                </div>
                <div class="wd-90p">
                  <div class="d-flex">
                    <h5 class="mb-1 name">Barney Cull</h5>
                    <p class="time mb-0 text-right ml-auto float-right">
                      30 min ago
                    </p>
                  </div>
                  <p class="mb-0 desc">Here are some products ...</p>
                </div>
              </a>
              <a href="javascript:;" class="p-3 d-flex border-bottom">
                <div style="position: relative">
                  <img
                    class="drop-img cover-image"
                    src="../../assets/img/faces/5.jpg"
                  />
                  <span class="avatar-status bg-teal"></span>
                </div>
                <div class="wd-90p">
                  <div class="d-flex">
                    <h5 class="mb-1 name">Petey Cruiser</h5>
                    <p class="time mb-0 text-right ml-auto float-right">
                      35 min ago
                    </p>
                  </div>
                  <p class="mb-0 desc">I'm sorry but i'm not sure how...</p>
                </div>
              </a>
            </div>
            <div class="text-center dropdown-footer">
              <a href="javascript:;">VIEW ALL</a>
            </div>
          </div>
        </div>
        <div ngbDropdown class="nav-item main-header-notification">
          <a ngbDropdownToggle class="new nav-link">
            <i class="ti-bell animated bell-animations"></i
            ><span class="pulse"></span>
          </a>
          <div ngbDropdownMenu class="dropdown-menu-arrow animated fadeInUp">
            <div class="main-dropdown-header d-sm-none">
              <a class="main-header-arrow" href="javascript:;"
                ><i class="icon ion-md-arrow-back"></i
              ></a>
            </div>
            <div class="menu-header-content text-left d-flex">
              <div class="my-auto">
                <h6 class="menu-header-title text-white mb-0">
                  7 New Notifications
                </h6>
              </div>
              <div class="my-auto ml-auto">
                <span class="badge badge-pill badge-warning float-right"
                  >Mark All Read</span
                >
              </div>
            </div>
            <div class="main-notification-list Notification-scroll">
              <a href="javascript:;" class="p-3 d-flex border-bottom">
                <div class="notifyimg bg-success-transparent">
                  <i class="la la-shopping-basket text-success"></i>
                </div>
                <div class="ml-3">
                  <h5 class="notification-label mb-1">New Order Received</h5>
                  <div class="notification-subtext">1 hour ago</div>
                </div>
                <div class="ml-auto">
                  <i class="las la-angle-right text-right text-muted"></i>
                </div>
              </a>
              <a href="javascript:;" class="p-3 d-flex border-bottom">
                <div class="notifyimg bg-danger-transparent">
                  <i class="la la-user-check text-danger"></i>
                </div>
                <div class="ml-3">
                  <h5 class="notification-label mb-1">
                    22 verified registrations
                  </h5>
                  <div class="notification-subtext">2 hour ago</div>
                </div>
                <div class="ml-auto">
                  <i class="las la-angle-right text-right text-muted"></i>
                </div>
              </a>
              <a href="javascript:;" class="p-3 d-flex border-bottom">
                <div class="notifyimg bg-primary-transparent">
                  <i class="la la-check-circle text-primary"></i>
                </div>
                <div class="ml-3">
                  <h5 class="notification-label mb-1">
                    Project has been approved
                  </h5>
                  <div class="notification-subtext">4 hour ago</div>
                </div>
                <div class="ml-auto">
                  <i class="las la-angle-right text-right text-muted"></i>
                </div>
              </a>
              <a href="javascript:;" class="p-3 d-flex border-bottom">
                <div class="notifyimg bg-pink-transparent">
                  <i class="la la-file-alt text-pink"></i>
                </div>
                <div class="ml-3">
                  <h5 class="notification-label mb-1">New files available</h5>
                  <div class="notification-subtext">10 hour ago</div>
                </div>
                <div class="ml-auto">
                  <i class="las la-angle-right text-right text-muted"></i>
                </div>
              </a>
              <a href="javascript:;" class="p-3 d-flex border-bottom">
                <div class="notifyimg bg-warning-transparent">
                  <i class="la la-envelope-open text-warning"></i>
                </div>
                <div class="ml-3">
                  <h5 class="notification-label mb-1">New review received</h5>
                  <div class="notification-subtext">1 day ago</div>
                </div>
                <div class="ml-auto">
                  <i class="las la-angle-right text-right text-muted"></i>
                </div>
              </a>
              <a href="javascript:;" class="p-3 d-flex border-bottom">
                <div class="notifyimg bg-purple-transparent">
                  <i class="la la-gem text-purple"></i>
                </div>
                <div class="ml-3">
                  <h5 class="notification-label mb-1">Updates Available</h5>
                  <div class="notification-subtext">2 days ago</div>
                </div>
                <div class="ml-auto">
                  <i class="las la-angle-right text-right text-muted"></i>
                </div>
              </a>
            </div>
            <div class="text-center dropdown-footer">
              <a href="javascript:;">VIEW ALL</a>
            </div>
          </div>
        </div>
        <div ngbDropdown class="main-profile-menu nav nav-item nav-link">
          <a
            ngbDropdownToggle
            class="d-flex profile-user"
            href="javascript:void(0)"
          >
            <img alt="avatar" src="../../assets/img/faces/5.jpg" />
          </a>
          <div ngbDropdownMenu class="dropdown-menu-arrow animated fadeInUp">
            <div class="main-header-profile header-img">
              <div class="main-img-user">
                <img alt="" src="../../assets/img/faces/5.jpg" />
              </div>
              <h6>{{ userData.first_name }}</h6>
              <span>Admin</span>
            </div>
            <a ngbDropdownItem class="border-top" routerLink="/pages/profile">
              <i class="far fa-user"></i> My Profile
            </a>
            <a
              ngbDropdownItem
              class="dropdown-item"
              routerLink="/pages/profile"
            >
              <i class="far fa-edit"></i> Edit Profile
            </a>

            <a ngbDropdownItem class="" routerLink="/pages/profile">
              <i class="fas fa-sliders-h"></i> Account Settings
            </a>
            <a ngbDropdownItem class="" routerLink="" (click)="onLogOut()">
              <i class="fas fa-sign-out-alt"></i> Sign Out
            </a>
          </div>
        </div>
        <!-- <div class="dropdown main-header-message right-toggle">
          <a
            href="javascript:void(0)"
            class="nav-link"
            (click)="toggleSidebarNotification()"
          >
            <i class="ti-menu tx-20 bg-transparent"></i>
          </a>
        </div> -->
      </div>
    </div>
  </div>
</div>
<!-- mobile-header -->
