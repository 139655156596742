<div>
  <h4>Please enter the 6 digit otp code for verify your login</h4>
  <ngb-alert type="danger" [dismissible]="false" *ngIf="error">
    <span class="alert-inner--icon"><i class="fe fe-slash mr-1"></i></span>
    <span class="alert-inner--text"> {{ error }}!</span>
  </ngb-alert>
  <div *ngIf="isLoading" class="text-center">
    <app-loader-spinner></app-loader-spinner>
  </div>
  <form [formGroup]="verifyForm" (ngSubmit)="verifyOtp()" *ngIf="!isLoading">
    <div class="form-group">
      <label>OTP Code</label><app-resend-otp></app-resend-otp>
      <input
        class="form-control"
        placeholder="Enter the otp code"
        type="text"
        formControlName="otp"
        required
        minlength="6"
      />
    </div>
    <button
      class="btn btn-main-primary btn-block"
      [disabled]="!verifyForm.valid"
    >
      Verify
    </button>
  </form>
</div>
