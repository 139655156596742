import { Component } from '@angular/core';
import { AuthService } from './authentication/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'TAAS';
  public isSpinner = true;
  constructor(private authService: AuthService) {}

  ngOnInit(): void {
    this.authService.authLogin();
    setTimeout(() => {
      this.isSpinner = false;
    }, 1000);
  }
}
