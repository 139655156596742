import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-loader-spinner',
  template: `<div class="lds-roller">
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </div>`,
  styleUrls: ['./loader-spinner.component.scss'],
})
export class LoaderSpinnerComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
