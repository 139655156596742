<div class="page">
  <!-- Main-signin-wrapper -->
  <div class="my-auto page">
    <div class="main-signin-wrapper">
      <div class="main-card-signin forgot-password d-md-flex wd-100p">
        <div
          class="wd-md-50p page-signin-style p-md-5 p-4 text-white d-none d-md-block"
        >
          <div class="my-auto authentication-pages">
            <div>
              <img
                src="../../assets/img/brand/iofs-logo.png"
                class="m-0 mb-4"
                alt="logo"
              />
              <h5 class="mb-4">First Stock Trading Service</h5>
              <p class="mb-5">
                Our service offerings ranges from trade executions, cscs
                creation, stocks analysis, portfolio management, economic/
                financial analysis and advisory.
              </p>
              <a
                href="https://tradingservicewebsite-dev.azurewebsites.net/contact"
                target="_blank"
                class="btn btn-danger"
                >Contact Us</a
              >
            </div>
          </div>
        </div>
        <div class="p-5 wd-md-50p">
          <div class="main-signin-header">
            <h2>Forgot Password!</h2>
            <div class="alert alert-danger" role="alert" *ngIf="error">
              {{ error }}
            </div>

            <div class="alert alert-success" role="alert" *ngIf="message">
              {{ message }}
            </div>
            <div *ngIf="isLoading" class="text-center">
              <app-loader-spinner></app-loader-spinner>
            </div>
            <h4 *ngIf="!isLoading">Please Enter Your Email</h4>
            <form [formGroup]="form" (ngSubmit)="onSubmit()" *ngIf="!isLoading">
              <div class="form-group">
                <label>Email</label>
                <input
                  class="form-control"
                  placeholder="Enter your email"
                  type="email"
                  formControlName="email"
                />
                <p
                  class="text-danger"
                  *ngIf="
                    (form.controls['email'].touched ||
                      form.controls['email'].dirty) &&
                    form.controls['email'].invalid
                  "
                >
                  {{ getError("email", "email") }}
                </p>
              </div>
              <button
                class="btn btn-main-primary btn-block"
                [disabled]="!form.valid"
              >
                Submit
              </button>
            </form>
          </div>
          <div class="main-signup-footer mg-t-10">
            <p>
              Forget it, <a routerLink="/login"> Send me back</a> to the sign in
              screen.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Main-signin-wrapper -->
</div>
