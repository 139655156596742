import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '../auth.service';
// import { registerAction } from '../store/action';

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss'],
})
export class SignUpComponent implements OnInit {
  isLoading = false;
  error: string = null;
  errors = [];
  message: string = null;

  regForm = this.fb.group({
    firstName: [
      '',
      [
        Validators.required,
        Validators.pattern('^[a-zA-Z][a-zA-Z-]{1,}[a-zA-Z]$'),
      ],
    ],
    lastName: [
      '',
      [
        Validators.required,
        Validators.pattern('^[a-zA-Z][a-zA-Z-]{1,}[a-zA-Z]$'),
      ],
    ],
    email: [
      '',
      [
        Validators.required,
        Validators.email,
        Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$'),
      ],
    ],
    companyCode: [
      '',
      [
        Validators.required,
        // Validators.pattern('^[0-9a-zA-Z]+(?:s[0-9a-zA-Z]+)+$'),
      ],
    ],
    companyName: [
      '',
      [
        Validators.required,
        // Validators.pattern('^[0-9a-zA-Z]+(?:s[0-9a-zA-Z]+)+$'),
      ],
    ],
    companyEmail: [
      '',
      [
        Validators.required,
        Validators.email,
        Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$'),
      ],
    ],
    password: ['', [Validators.required]],
  });
  constructor(
    private fb: FormBuilder,
    public router: Router,
    private authService: AuthService
  ) {}

  ngOnInit(): void {}
  onSubmit() {
    // TODO: Use EventEmitter with form value
    if (!this.regForm.valid) {
      this.regForm.markAllAsTouched();
      return;
    }
    this.message = '';
    this.errors = [];
    const first_name = this.regForm.value['firstName'];
    const last_name = this.regForm.value['lastName'];
    const email = this.regForm.value['email'];
    const company_email = this.regForm.value['companyEmail'];
    const company_code = this.regForm.value['companyCode'];
    const company_name = this.regForm.value['companyName'];
    const password = this.regForm.value['password'];
    this.isLoading = true;
    this.authService
      .signup(
        first_name,
        last_name,
        email,
        company_code,
        company_name,
        company_email,
        password
      )
      .subscribe(
        (resData) => {
          this.isLoading = false;
          if (resData.status == 200) {
            this.message = 'Thank you. Please check your mail to proceed.';
          }
        },
        (error) => {
          this.isLoading = false;
          if (error.error.errors) {
            if (error.error.errors.password) {
              this.errors.push(error.error.errors.password);
            }
            if (error.error.errors.email) {
              this.errors.push(error.error.errors.email);
            }
            if (error.error.errors.company_email) {
              this.errors.push(error.error.errors.company_email);
            }
            if (error.error.errors.company_code) {
              this.errors.push(error.error.errors.company_code);
            }
            if (error.error.errors.first_name) {
              this.errors.push(error.error.errors.first_name);
            }
            if (error.error.errors.last_name) {
              this.errors.push(error.error.errors.last_name);
            }
          } else {
            this.errors.push(
              'An unknown error occured, try again later please.'
            );
          }
        }
      );
  }
  getError(formControName: string, validatorName: string): string {
    return this.errorMessage(formControName, validatorName);
  }
  private errorMessage(formControName: string, validatorName: string): string {
    switch (formControName) {
      case 'email':
      case 'companyEmail':
        return 'Enter a valid email Address';
      case 'firstName':
      case 'lastName':
      case 'companyName':
        return 'Enter a valid value';
      case 'companyCode':
        return 'Enter a valid value';
      default:
        return 'Enter a valid value';
    }
  }
}
