import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DataStorageService } from 'src/app/shared/services/data-storage.service';

@Component({
  selector: 'app-forget-password',
  templateUrl: './forget-password.component.html',
  styleUrls: ['./forget-password.component.scss'],
})
export class ForgetPasswordComponent implements OnInit {
  isLoading = false;
  error: string = null;
  message: string = null;
  form = this.fb.group({
    email: [
      '',
      [
        Validators.required,
        Validators.email,
        Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$'),
      ],
    ],
  });

  constructor(
    private fb: FormBuilder,
    private dataStorage: DataStorageService
  ) {}

  ngOnInit(): void {}
  onSubmit() {
    // TODO: Use EventEmitter with form value
    this.error = '';
    if (!this.form.valid) {
      this.form.markAllAsTouched();
      return;
    }

    const data = {
      email: this.form.value['email'],
    };
    this.isLoading = true;
    return this.dataStorage
      .authPostNoAuth('user/forgot-password/send-email', data)
      .subscribe(
        (resData) => {
          this.isLoading = false;
          if (resData.status === 200) {
            this.message = resData.message;
          }
        },
        (error) => {
          this.isLoading = false;
          this.error = error.error.message;
        }
      );
  }
  getError(formControName: string, validatorName: string): string {
    return this.errorMessage(formControName, validatorName);
  }
  private errorMessage(formControName: string, validatorName: string): string {
    switch (formControName) {
      case 'email':
        return 'Enter a valid email Address';
      default:
        return 'Enter a valid value';
    }
  }
  sendEmail(email: string) {
    this.isLoading = true;
    const data = {
      email: email,
    };
    this.dataStorage.authPostNoAuth('user/forgot-password/send-email', data);
  }
}
