<div class="my-auto page page-h">
  <div class="main-signin-wrapper">
    <div class="main-card-signin d-md-flex wd-100p">
      <div
        class="wd-md-50p login d-none d-md-block page-signin-style p-5 text-white"
      >
        <div class="my-auto authentication-pages">
          <div>
            <img
              src="../../assets/img/brand/iofs-logo.png"
              class="m-0 mb-4"
              alt="logo"
            />
            <h5 class="mb-4">First Stock Trading Service</h5>
            <p class="mb-5">
              Our service offerings ranges from trade executions, cscs creation,
              stocks analysis, portfolio management, economic/ financial
              analysis and advisory.
            </p>
            <a
              href="https://tradingservicewebsite-dev.azurewebsites.net/"
              target="_blank"
              class="btn btn-danger"
              >Learn More</a
            >
          </div>
        </div>
      </div>

      <div class="sign-up-body wd-md-50p">
        <div class="main-signin-header">
          <h2>Welcome back!</h2>
          <h4>Please Register here</h4>
          <div *ngIf="errors">
            <p *ngFor="let error of errors">
              <ngb-alert type="danger" (closed)="close(error)">{{
                error
              }}</ngb-alert>
            </p>
          </div>

          <ngb-alert type="success" [dismissible]="false" *ngIf="message">
            <span class="alert-inner--icon"
              ><i class="fe fe-slash mr-1"></i
            ></span>
            <span class="alert-inner--text"> {{ message }}!</span>
          </ngb-alert>
          <div *ngIf="isLoading" class="text-center">
            <app-loader-spinner></app-loader-spinner>
          </div>
          <form
            [formGroup]="regForm"
            (ngSubmit)="onSubmit()"
            *ngIf="!isLoading"
          >
            <div class="form-group">
              <label>Firstname</label>
              <input
                class="form-control"
                placeholder="Enter your firstname"
                type="text"
                formControlName="firstName"
                required
              />
              <p
                class="text-danger"
                *ngIf="
                  (regForm.controls['firstName'].touched ||
                    regForm.controls['firstName'].dirty) &&
                  regForm.controls['firstName'].invalid
                "
              >
                {{ getError("firstName", "firstName") }}
              </p>
            </div>
            <div class="form-group">
              <label>Lastname</label>
              <input
                class="form-control"
                placeholder="Enter your lastname"
                type="text"
                formControlName="lastName"
                required
              />
              <p
                class="text-danger"
                *ngIf="
                  (regForm.controls['lastName'].touched ||
                    regForm.controls['lastName'].dirty) &&
                  regForm.controls['lastName'].invalid
                "
              >
                {{ getError("lastName", "lastName") }}
              </p>
            </div>
            <div class="form-group">
              <label>Email</label>
              <input
                class="form-control"
                placeholder="Enter your email"
                type="email"
                formControlName="email"
                required
              />
              <p
                class="text-danger"
                *ngIf="
                  (regForm.controls['email'].touched ||
                    regForm.controls['email'].dirty) &&
                  regForm.controls['email'].invalid
                "
              >
                {{ getError("email", "email") }}
              </p>
            </div>
            <div class="form-group">
              <label>Merchant Code</label>
              <input
                class="form-control"
                placeholder="Enter your merchant code"
                type="text"
                formControlName="companyCode"
                required
              />
            </div>
            <div class="form-group">
              <label>Company Name</label>
              <input
                class="form-control"
                placeholder="Enter your company name"
                type="text"
                formControlName="companyName"
                required
              />
            </div>
            <div class="form-group">
              <label>Company Email</label>
              <input
                class="form-control"
                placeholder="Enter your company email address"
                type="email"
                formControlName="companyEmail"
                required
              />
              <p
                class="text-danger"
                *ngIf="
                  (regForm.controls['companyEmail'].touched ||
                    regForm.controls['companyEmail'].dirty) &&
                  regForm.controls['companyEmail'].invalid
                "
              >
                {{ getError("email", "email") }}
              </p>
            </div>
            <div class="form-group">
              <label>Password</label>
              <input
                class="form-control"
                placeholder="Enter your password"
                type="password"
                formControlName="password"
                required
              />
            </div>
            <button
              class="btn btn-main-primary btn-block"
              type="submit"
              [disabled]="!regForm.valid"
            >
              Create Account
            </button>
          </form>
        </div>
        <div class="main-signup-footer mg-t-10">
          <p>
            Already have an account?
            <a routerLink="/authentication/sign-in">Sign In</a>
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
