<!-- breadcrumb -->
<div class="breadcrumb-header justify-content-between">
  <div class="left-content">
    <h3 class="content-title mb-2">Welcome back,</h3>
    <div class="d-flex">
      <i class="mdi mdi-home text-muted hover-cursor"></i>
      <p class="text-muted mb-0 hover-cursor">
        &nbsp;/&nbsp;Dashboard&nbsp;/&nbsp;
      </p>
      <p class="text-primary mb-0 hover-cursor">{{ first_name }}</p>
    </div>
  </div>
  <div
    class="d-flex align-items-end flex-wrap my-auto right-content breadcrumb-right"
  >
    <!-- <button type="button" class="btn btn-warning btn-icon mr-3 mt-2 mt-xl-0">
      <i class="mdi mdi-download"></i>
    </button>
    <button type="button" class="btn btn-danger btn-icon mr-3 mt-2 mt-xl-0">
      <i class="mdi mdi-clock"></i>
    </button>
    <button type="button" class="btn btn-success btn-icon mr-3 mt-2 mt-xl-0">
      <i class="mdi mdi-plus"></i>
    </button> -->
    <!-- <span class="mr-2 font-weight-semibold">{{
      status1 ? "Live" : "Test"
    }}</span>
    <div
      class="main-toggle main-toggle-success"
      (click)="clickEvent1()"
      [ngClass]="status1 ? 'on' : ''"
    >
      <span></span>
    </div> -->
  </div>
</div>
<!-- /breadcrumb -->

<div class="col-xl-12 col-md-12 col-lg-12">
  <div
    class="customCarousel overflow-hidden bg-transparent card-crypto-scroll shadow-none"
  >
    <div *ngIf="isLoadingStock" class="text-center">
      <app-loader-spinner></app-loader-spinner>
    </div>
    <owl-carousel-o [options]="customOptions">
      <ng-template carouselSlide *ngFor="let stock of topGainers">
        <div class="card">
          <div class="card-body">
            <div class="d-flex align-items-center">
              <!-- <div class="">
                <img [src]="" class="w-6 h-6 mt-0" alt="" />
              </div> -->
              <div class="ml-3">
                <p class="tx-13 mb-1">{{ stock.StockCode }}</p>
                <div class="m-0 tx-13 text-warning d-flex">
                  ₦{{ stock.Price | number: "1.2-2" }}
                  <span class="text-success ml-2"
                    ><i class="ion-arrow-down-c mr-1"></i>+{{
                      stock.PercentChange | number: "1.2-2"
                    }}</span
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-template>
    </owl-carousel-o>
  </div>
</div>

<!-- row -->
<div class="row row-sm">
  <div class="col-xl-4 col-lg-6 col-md-12">
    <div class="card crypto crypt-primary overflow-hidden">
      <div class="card-body iconfont text-left">
        <div class="media">
          <div class="coin-logo bg-primary-transparent">
            <i class="cf cf-eth text-primary"></i>
          </div>
          <div class="media-body">
            <h6>ZENITH</h6>
            <p>
              ZENITH (NGN) = ₦28.46 (<span class="text-danger">-12.24%</span>)
            </p>
          </div>
          <!-- <div class="card-body-top mb-3">
            <div><a href="javascript:;">14</a> Bid</div>
            <div><a href="javascript:;">82</a> Offer</div>
          </div> -->
        </div>
        <div class="flot-wrapper">
          <!-- <div class="flot-chart ht-150 mt-4" id="flotChart3"></div> -->
          <div *ngIf="isLoadingZenith" class="text-center">
            <app-loader-spinner></app-loader-spinner>
          </div>
          <apx-chart
            [chart]="zenithOptions.chart"
            [xaxis]="zenithOptions.xaxis"
            [series]="zenithOptions.series"
            [stroke]="zenithOptions.stroke"
            [labels]="zenithOptions.labels"
          >
          </apx-chart>
        </div>
      </div>
      <div class="card-footer">
        <nav class="nav">
          <a class="nav-link active" href="javascript:;"
            ><span>1D</span><span>-12.24%</span></a
          >
          <a class="nav-link" href="javascript:;"
            ><span>1W</span><span>-16.48%</span></a
          >
          <a class="nav-link" href="javascript:;"
            ><span>1M</span><span>-15.21%</span></a
          >
          <a class="nav-link" href="javascript:;"
            ><span>1Y</span><span>-40.17%</span></a
          >
        </nav>
      </div>
    </div>
  </div>
  <div class="col-xl-4 col-lg-6 col-md-12">
    <div class="card crypto crypt-danger overflow-hidden">
      <div class="card-body iconfont text-left">
        <div class="media">
          <div class="coin-logo bg-danger-transparent">
            <i class="cf cf-ltc tx-18 text-danger"></i>
          </div>
          <div class="media-body">
            <h6>DANGOTE CEMENT</h6>
            <p>
              DANGCEM (NGN) = ₦0.81 (<span class="text-danger">-1.24%</span>)
            </p>
          </div>
          <!-- <div class="card-body-top">
            <div><a href="javascript:;">21</a> Bid</div>
            <div><a href="javascript:;">56</a> Offer</div>
          </div> -->
        </div>
        <div class="flot-wrapper">
          <!-- <div class="flot-chart ht-150  mt-4" id="flotChart5"></div> -->
          <apx-chart
            [chart]="dangcemOptions.chart"
            [xaxis]="dangcemOptions.xaxis"
            [series]="dangcemOptions.series"
            [stroke]="dangcemOptions.stroke"
            [labels]="dangcemOptions.labels"
          ></apx-chart>
        </div>
      </div>
      <div class="card-footer">
        <nav class="nav">
          <a class="nav-link active" href="javascript:;"
            ><span>1D</span><span>-15.24%</span></a
          >
          <a class="nav-link" href="javascript:;"
            ><span>1W</span><span>-24.68%</span></a
          >
          <a class="nav-link" href="javascript:;"
            ><span>1M</span><span>-17.15%</span></a
          >
          <a class="nav-link" href="javascript:;"
            ><span>1Y</span><span>-30.23%</span></a
          >
        </nav>
      </div>
    </div>
  </div>
  <div class="col-xl-4 col-lg-12 col-md-12">
    <div class="card crypto crypt-success overflow-hidden">
      <div class="card-body iconfont text-left">
        <div class="media">
          <div class="coin-logo bg-success-transparent">
            <i class="cf cf-xrp text-success"></i>
          </div>
          <div class="media-body">
            <h6>SEPLAT</h6>
            <p>
              SEPLAT (NGN) = ₦0.295(<span class="text-danger">-2.97%</span>)
            </p>
          </div>
          <!-- <div class="card-body-top">
            <div><a href="javascript:;">4</a> USD Markets</div>
            <div><a href="javascript:;">45</a> LTC Markets</div>
          </div> -->
        </div>
        <div class="flot-wrapper">
          <!-- <div class="flot-chart ht-150 mt-4" id="flotChart1"></div> -->
          <apx-chart
            [chart]="seplatOptions.chart"
            [xaxis]="seplatOptions.xaxis"
            [series]="seplatOptions.series"
            [stroke]="seplatOptions.stroke"
            [labels]="seplatOptions.labels"
          ></apx-chart>
        </div>
      </div>
      <div class="card-footer">
        <nav class="nav">
          <a class="nav-link active" href="javascript:;"
            ><span>1D</span><span>-14.32%</span></a
          >
          <a class="nav-link" href="javascript:;"
            ><span>1W</span><span>-24.39%</span></a
          >
          <a class="nav-link" href="javascript:;"
            ><span>1M</span><span>-42.12%</span></a
          >
          <a class="nav-link" href="javascript:;"
            ><span>1Y</span><span>-50.34%</span></a
          >
        </nav>
      </div>
    </div>
  </div>
</div>
<!-- /row -->

<!-- row -->
<div class="row row-sm row-deck">
  <div class="col-xl-4 col-lg-12">
    <div class="card overflow-hidden">
      <div class="card-header pb-0">
        <div class="d-flex justify-content-between">
          <h4 class="card-title mg-b-10">Market Info</h4>
          <div class="ml-auto mr-2">
            <div ngbDropdown class="show">
              <a class="new" ngbDropdownToggle type="button">
                <i class="fas fa-ellipsis-v text-muted"></i>
              </a>
              <!-- <div class="dropdown-menu-left" ngbDropdownMenu>
                <a ngbDropdownItem href="javascript:;">Activity </a>
                <a ngbDropdownItem href="javascript:;">Personal Settings</a>
              </div> -->
            </div>
          </div>
        </div>
        <p class="tx-12 tx-gray-500 mb-2">
          An activity is a scheduled phase in a project plan with a distinct
          beginning and end. <a href="javascript:;">Learn more</a>
        </p>
      </div>
      <div class="card-body p-0">
        <div class="">
          <div
            class="list d-flex align-items-center border-bottom p-2 pl-3 pr-3 mt-0"
          >
            <img
              class="w-6 h-6"
              src="../../assets/img/crypto-currencies/round-outline/Bitcoin.svg  "
              alt="image"
            />
            <div class="w-100 ml-3">
              <div class="d-flex justify-content-between align-items-center">
                <p class="mt-1 mb-0 font-weight-semibold">GTCO</p>
                <span class="ml-auto fs-15 mb-0 font-weight-semibold"
                  >₦23.5</span
                >
              </div>
              <div class="d-flex justify-content-between align-items-center">
                <span class="text-success tx-13"
                  ><i class="fa fa-caret-up mr-1"></i>2.04%</span
                >
                <!-- <small class="text-muted ml-auto">340.5 USD</small> -->
              </div>
            </div>
          </div>
          <div
            class="list d-flex align-items-center border-bottom p-2 pl-3 pr-3"
          >
            <img
              class="w-6 h-6"
              src="../../assets/img/crypto-currencies/round-outline/Ethereum.svg"
              alt="image"
            />
            <div class="w-100 ml-3">
              <div class="d-flex justify-content-between align-items-center">
                <p class="mt-1 mb-0 font-weight-semibold">TOTAL</p>
                <span class="ml-auto fs-15 mb-0 font-weight-semibold"
                  >₦57.6</span
                >
              </div>
              <div class="d-flex justify-content-between align-items-center">
                <span class="text-danger tx-13"
                  ><i class="fa fa-caret-down mr-1"></i>1.25%</span
                >
                <!-- <small class="text-muted ml-auto">283.5 USD</small> -->
              </div>
            </div>
          </div>
          <div
            class="list d-flex align-items-center border-bottom p-2 pl-3 pr-3"
          >
            <img
              class="w-6 h-6"
              src="../../assets/img/crypto-currencies/round-outline/Litecoin.svg"
              alt="image"
            />
            <div class="w-100 ml-3">
              <div class="d-flex justify-content-between align-items-center">
                <p class="mt-1 mb-0 font-weight-semibold">SEPLAT</p>
                <span class="ml-auto fs-15 mb-0 font-weight-semibold"
                  >₦87.5</span
                >
              </div>
              <div class="d-flex justify-content-between align-items-center">
                <span class="text-danger tx-13"
                  ><i class="fa fa-caret-down mr-1"></i>1.04%</span
                >
                <!-- <small class="text-muted ml-auto">368.2 USD</small> -->
              </div>
            </div>
          </div>
          <div
            class="list d-flex align-items-center border-bottom p-2 pl-3 pr-3"
          >
            <img
              class="w-6 h-6"
              src="../../assets/img/crypto-currencies/round-outline/Dash.svg"
              alt="image"
            />
            <div class="w-100 ml-3">
              <div class="d-flex justify-content-between align-items-center">
                <p class="mt-1 mb-0 font-weight-semibold">OKOMUOIL</p>
                <span class="ml-auto fs-15 mb-0 font-weight-semibold"
                  >₦24.4</span
                >
              </div>
              <div class="d-flex justify-content-between align-items-center">
                <span class="text-success tx-13"
                  ><i class="fa fa-caret-up mr-1"></i>2.04%</span
                >
                <!-- <small class="text-muted ml-auto">127.3 USD</small> -->
              </div>
            </div>
          </div>
          <div
            class="list d-flex align-items-center border-bottom p-2 pl-3 pr-3 mb-0"
          >
            <img
              class="w-6 h-6"
              src="../../assets/img/crypto-currencies/round-outline/NEM.svg"
              alt="image"
            />
            <div class="w-100 ml-3">
              <div class="d-flex justify-content-between align-items-center">
                <p class="mt-1 mb-0 font-weight-semibold">FBNH</p>
                <span class="ml-auto fs-15 mb-0 font-weight-semibold"
                  >₦15.3</span
                >
              </div>
              <div class="d-flex justify-content-between align-items-center">
                <span class="text-success tx-13"
                  ><i class="fa fa-caret-up mr-1"></i>1.04%</span
                >
                <!-- <small class="text-muted ml-auto">165.8 USD</small> -->
              </div>
            </div>
          </div>
          <div class="list d-flex align-items-center p-2 pl-3 pr-3 mb-0">
            <img
              class="w-6 h-6"
              src="./assets/img/crypto-currencies/round-outline/Augur.svg"
              alt="image"
            />
            <div class="w-100 ml-3">
              <div class="d-flex justify-content-between align-items-center">
                <p class="mt-1 mb-0 font-weight-semibold">FLOURMILL</p>
                <span class="ml-auto fs-15 mb-0 font-weight-semibold"
                  >₦21.5</span
                >
              </div>
              <div class="d-flex justify-content-between align-items-center">
                <span class="text-success tx-13"
                  ><i class="fa fa-caret-up mr-1"></i>0.78%</span
                >
                <!-- <small class="text-muted ml-auto">205.8 USD</small> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-xl-8 col-lg-12">
    <div class="card card-bitcoin">
      <div class="card-minimal-two">
        <div class="card-header">
          <h4 class="card-title mb-0">Transaction Overtime</h4>
          <div class="">
            <nav ngbNav #nav="ngbNav" class="nav-pills" [(activeId)]="active">
              <!-- <ng-container ngbNavItem>
                <a ngbNavLink active>BTH </a>
              </ng-container>
              <ng-container ngbNavItem>
                <a ngbNavLink>ETH</a>
              </ng-container> -->
              <ng-container [ngbNavItem]="1">
                <a ngbNavLink>BUY</a>
                <ng-template ngbNavContent>
                  <div class="card-body overflow-hidden">
                    <div class="flot-wrapper">
                      <apx-chart
                        [series]="RandomData.series"
                        [colors]="RandomData.colors"
                        [chart]="RandomData.chart"
                        [dataLabels]="RandomData.dataLabels"
                        [markers]="RandomData.markers"
                        [xaxis]="RandomData.xaxis"
                        [tooltip]="RandomData.tooltip"
                        [fill]="RandomData.fill"
                      >
                      </apx-chart>
                    </div>
                  </div>
                </ng-template>
              </ng-container>
              <ng-container [ngbNavItem]="2">
                <a ngbNavLink>SELL</a>
                <ng-template ngbNavContent>
                  <div class="card-body overflow-hidden">
                    <div class="flot-wrapper">
                      <apx-chart
                        [series]="RandomData.series"
                        [colors]="RandomData.colors"
                        [chart]="RandomData.chart"
                        [dataLabels]="RandomData.dataLabels"
                        [markers]="RandomData.markers"
                        [xaxis]="RandomData.xaxis"
                        [tooltip]="RandomData.tooltip"
                        [fill]="RandomData.fill"
                      >
                      </apx-chart>
                    </div>
                  </div>
                </ng-template>
              </ng-container>
            </nav>
          </div>
          <!-- card-header-right -->
        </div>
      </div>
      <div [ngbNavOutlet]="nav" class=""></div>
    </div>
  </div>
</div>

<!-- /row  -->

<!-- row -->
<!-- <div class="row row-sm">
  <div class="col-md-12 col-xl-4 col-lg-12">
    <div class="card overflow-hidden recent-operations-card">
      <div class="card-body p-0">
        <div class="p-3 pb-0">
          <div class="d-flex justify-content-between">
            <h4 class="card-title mg-b-10">Transactions History</h4>
            <div class="ml-auto mr-2">
              <div ngbDropdown class="show">
                <a class="new" ngbDropdownToggle type="button">
                  <i class="fas fa-ellipsis-v text-muted"></i>
                </a>
                <div class="dropdown-menu-left" ngbDropdownMenu>
                  <a ngbDropdownItem href="javascript:;">Transcations Info </a>
                  <a ngbDropdownItem href="javascript:;"
                    >Transcation Settings</a
                  >
                </div>
              </div>
            </div>
          </div>
          <p class="tx-12 tx-gray-500 mb-0">
            Transaction History shows information about cash deposits, cash
            withdrawals for your account.<a href="javascript:;">Learn more</a>
          </p>
        </div>
        <div class="transcation-scroll" id="transcation-scroll">
          <ul class="list-unstyled mg-b-0 mt-2">
            <li class="list-item pl-3 pr-3 mb-0 pb-3">
              <div class="media align-items-center">
                <div
                  class="wd-40 ht-40 bg-orange-transparent tx-orange rounded-circle align-items-center justify-content-center d-none d-sm-flex"
                >
                  <i class="cf cf-btc wd-20 ht-20 text-center tx-20"></i>
                </div>
                <div class="media-body mg-sm-l-15">
                  <p class="tx-medium mg-b-3">Sell</p>
                  <p class="tx-11 mg-b-0 tx-gray-500">Customer No: 71532</p>
                </div>
              </div>
              <div class="text-right ml-auto">
                <p class="mg-b-3 font-weight-semibold">31 ZENITH</p>
                <p class="tx-11 mg-b-0 tx-gray-500">
                  <span class="text-success">₦2500.00 </span>NGN
                </p>
              </div>
            </li>
            <li class="list-item mb-0 pl-3 pr-3 pb-3">
              <div class="media align-items-center">
                <div
                  class="wd-40 ht-40 bg-teal-transparent tx-teal rounded-circle align-items-center justify-content-center d-none d-sm-flex"
                >
                  <i class="cf cf-ltc wd-20 ht-20 text-center tx-16"></i>
                </div>
                <div class="media-body mg-sm-l-15">
                  <p class="tx-medium mg-b-3">Sell</p>
                  <p class="tx-11 mg-b-0 tx-gray-500">Customer No: 71532</p>
                </div>
              </div>
              <div class="text-right ml-auto">
                <p class="mg-b-3 font-weight-semibold">0.0147 LTC</p>
                <p class="tx-11 mg-b-0 tx-gray-500">
                  <span class="text-danger">- $12.00 </span>USD
                </p>
              </div>
            </li>
            <li class="list-item mb-0 pl-3 pr-3 pb-3">
              <div class="media align-items-center">
                <div
                  class="wd-40 ht-40 bg-danger-transparent rounded-circle align-items-center justify-content-center d-none d-sm-flex"
                >
                  <i class="cf cf-eth wd-20 ht-20 text-danger tx-20"></i>
                </div>
                <div class="media-body mg-sm-l-15">
                  <p class="tx-medium mg-b-3">Buy</p>
                  <p class="tx-11 mg-b-0 tx-gray-500">Customer No: 71532</p>
                </div>
              </div>
              <div class="text-right ml-auto">
                <p class="mg-b-3 font-weight-semibold">2.0157 ETH</p>
                <p class="tx-11 mg-b-0 tx-gray-500">
                  <span class="text-success">+ $24.00 </span>USD
                </p>
              </div>
            </li>
            <li class="list-item mb-0 pl-3 pr-3 pb-3">
              <div class="media align-items-center">
                <div
                  class="wd-40 ht-40 bg-primary-transparent rounded-circle align-items-center justify-content-center d-none d-sm-flex"
                >
                  <i class="cf cf-dash wd-20 ht-20 text-primary tx-20"></i>
                </div>
                <div class="media-body mg-sm-l-15">
                  <p class="tx-medium mg-b-3">Sent Dash</p>
                  <p class="tx-11 mg-b-0 tx-gray-500">
                    Customer No: 03E25DAEYWZXB04
                  </p>
                </div>
              </div>
              <div class="text-right ml-auto">
                <p class="mg-b-3 font-weight-semibold">0.032 Dash</p>
                <p class="tx-11 mg-b-0 tx-gray-500">
                  <span class="text-danger">- $128.39 </span>USD
                </p>
              </div>
            </li>
            <li class="list-item mb-0 pl-3 pr-3 pb-3">
              <div class="media align-items-center">
                <div
                  class="wd-40 ht-40 bg-success-transparent tx-success rounded-circle align-items-center justify-content-center d-none d-sm-flex"
                >
                  <i class="cf cf-xmr wd-20 ht-20 text-center tx-20"></i>
                </div>
                <div class="media-body mg-sm-l-15">
                  <p class="tx-medium mg-b-3">Sent Bitcoin</p>
                  <p class="tx-11 mg-b-0 tx-gray-500">
                    Customer No: 03E25DAEYWZXB05
                  </p>
                </div>
              </div>
              <div class="text-right ml-auto">
                <p class="mg-b-3 font-weight-semibold">0.0157 BTC</p>
                <p class="tx-11 mg-b-0 tx-gray-500">
                  <span class="text-danger">- $149.00</span> USD
                </p>
              </div>
            </li>
            <li class="list-item mb-0 pl-3 pr-3 pb-3">
              <div class="media align-items-center">
                <div
                  class="wd-40 ht-40 bg-purple-transparent tx-purple rounded-circle align-items-center justify-content-center d-none d-sm-flex"
                >
                  <i class="cf cf-xrp wd-20 ht-20 text-center tx-20"></i>
                </div>
                <div class="media-body mg-sm-l-15">
                  <p class="tx-medium mg-b-3">Pending Ripple</p>
                  <p class="tx-11 mg-b-0 tx-gray-500">
                    Customer No: 03E25DAEYWZXB06
                  </p>
                </div>
              </div>
              <div class="text-right ml-auto">
                <p class="mg-b-3 font-weight-semibold">0.0258 XRP</p>
                <p class="tx-11 mg-b-0 tx-gray-500">
                  <span class="text-success">+ $235.00</span> USD
                </p>
              </div>
            </li>
            <li class="list-item mb-0 pl-3 pr-3 pb-3">
              <div class="media align-items-center">
                <div
                  class="wd-40 ht-40 bg-warning-transparent tx-warning rounded-circle align-items-center justify-content-center d-none d-sm-flex"
                >
                  <i class="cf cf-btc wd-20 ht-20 text-center tx-20"></i>
                </div>
                <div class="media-body mg-sm-l-15">
                  <p class="tx-medium mg-b-3">Pending Bitcoin</p>
                  <p class="tx-11 mg-b-0 tx-gray-500">
                    Customer No: 03E25DAEYWZXB07
                  </p>
                </div>
              </div>
              <div class="text-right ml-auto">
                <p class="mg-b-3 font-weight-semibold">0.0235 BTC</p>
                <p class="tx-11 mg-b-0 tx-gray-500">
                  <span class="text-success">+ $345.00</span> USD
                </p>
              </div>
            </li>
            <li class="list-item mb-0 pl-3 pr-3 pb-3">
              <div class="media align-items-center">
                <div
                  class="wd-40 ht-40 bg-pink-transparent tx-pink rounded-circle align-items-center justify-content-center d-none d-sm-flex"
                >
                  <i class="cf cf-eth wd-20 ht-20 text-center tx-20"></i>
                </div>
                <div class="media-body mg-sm-l-15">
                  <p class="tx-medium mg-b-3">Sent Ethereum</p>
                  <p class="tx-11 mg-b-0 tx-gray-500">
                    Customer No: 03E25DAEYWZXB08
                  </p>
                </div>
              </div>
              <div class="text-right ml-auto">
                <p class="mg-b-3 font-weight-semibold">0.0020 ETH</p>
                <p class="tx-11 mg-b-0 tx-gray-500">
                  <span class="text-danger">- $245.00</span> USD
                </p>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>

  <div class="col-xl-8 col-lg-12">
    <div class="row row-sm">
      <div class="col-lg-6">
        <div class="card">
          <div class="card-body text-center crypto-buy-sell">
            <div class="d-flex justify-content-between">
              <h4 class="card-title">Buy Crypto Coins</h4>
              <div class="ml-auto mr-2">
                <div ngbDropdown class="show">
                  <a class="new" ngbDropdownToggle type="button">
                    <i class="fas fa-ellipsis-h text-muted"></i>
                  </a>
                  <div class="dropdown-menu-left" ngbDropdownMenu>
                    <a ngbDropdownItem href="javascript:;">Buying Rules </a>
                    <a ngbDropdownItem href="javascript:;">Crypto Settings</a>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group mt-1">
              <label class="form-label float-left">Enter Your Money</label>
              <div class="input-group">
                <input type="text" class="form-control" Value="10,000" />
                <div class="input-group-append wd-100">
                  <ng-select
                    class="form-control"
                    [items]="select1Currency"
                    bindLabel="title"
                    bindvalue="id"
                    groupBy="currency"
                    placeholder="Choose One"
                  >
                    <ng-template ng-optgroup-tmp let-item="item">{{
                      item.title
                    }}</ng-template>
                  </ng-select>
                </div>
              </div>
            </div>
            <div class="form-group">
              <label class="form-label float-left"
                >Your Crypto Coins Converted to</label
              >
              <div class="input-group">
                <input type="text" class="form-control" Value="0.254" />
                <div class="input-group-append wd-100">
                  <ng-select
                    class="form-control"
                    [items]="select1Coins"
                    bindLabel="title"
                    bindvalue="id"
                    groupBy="coins"
                    placeholder="Choose One"
                  >
                    <ng-template ng-optgroup-tmp let-item="item">{{
                      item.title
                    }}</ng-template>
                  </ng-select>
                </div>
              </div>
            </div>
            <a class="btn btn-block btn-success" href="javascript:;"
              >Buy Coins</a
            >
          </div>
        </div>
      </div>
      <div class="col-lg-6">
        <div class="card">
          <div class="card-body text-center">
            <div class="d-flex justify-content-between">
              <h4 class="card-title">Sell Crypto Coins</h4>
              <div class="ml-auto mr-2">
                <div ngbDropdown class="show">
                  <a class="new" ngbDropdownToggle type="button">
                    <i class="fas fa-ellipsis-h text-muted"></i>
                  </a>
                  <div class="dropdown-menu-left" ngbDropdownMenu>
                    <a ngbDropdownItem href="javascript:;">Selling Rules </a>
                    <a ngbDropdownItem href="javascript:;">Crypto Settings</a>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group mt-1">
              <label class="form-label float-left">Enter Crypto Coin</label>
              <div class="input-group">
                <input type="text" class="form-control" Value="0.254" />
                <div class="input-group-append wd-100">
                  <ng-select
                    class="form-control"
                    [items]="select2Coins"
                    bindLabel="title"
                    bindvalue="id"
                    groupBy="coins"
                    placeholder="Choose One"
                  >
                    <ng-template ng-optgroup-tmp let-item="item">{{
                      item.title
                    }}</ng-template>
                  </ng-select>
                </div>
              </div>
            </div>
            <div class="form-group">
              <label class="form-label float-left"
                >Your Money Converted to</label
              >
              <div class="input-group">
                <input type="text" class="form-control" Value="10,000" />
                <div class="input-group-append wd-100">
                  <ng-select
                    class="form-control"
                    [items]="select2Currency"
                    bindLabel="title"
                    bindvalue="id"
                    groupBy="currency"
                    placeholder="Choose One"
                  >
                    <ng-template ng-optgroup-tmp let-item="item">{{
                      item.title
                    }}</ng-template>
                  </ng-select>
                </div>
              </div>
            </div>
            <a class="btn btn-block btn-primary" href="javascript:;"
              >Sell Coins</a
            >
          </div>
        </div>
      </div>
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="card-title mb-4">Quick Conversion</div>
            <div class="row">
              <div class="col-lg-3 col-md-6">
                <div class="form-group">
                  <label class="form-label float-left">From</label>
                  <div class="input-group">
                    <div class="input-group-append w-100">
                      <ng-select
                        class="form-control"
                        [items]="select3Coins"
                        bindLabel="title"
                        bindvalue="id"
                        groupBy="coins"
                        placeholder="Choose One"
                      >
                        <ng-template ng-optgroup-tmp let-item="item">{{
                          item.title
                        }}</ng-template>
                      </ng-select>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-3 col-md-6">
                <div class="form-group">
                  <label class="form-label float-left">To</label>
                  <div class="input-group">
                    <div class="input-group-append w-100">
                      <ng-select
                        class="form-control"
                        [items]="select3Currency"
                        bindLabel="title"
                        bindvalue="id"
                        groupBy="currency"
                        placeholder="Choose One"
                      >
                        <ng-template ng-optgroup-tmp let-item="item">{{
                          item.title
                        }}</ng-template>
                      </ng-select>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-3 col-md-6">
                <div class="form-group">
                  <label class="form-label float-left">Amount</label>
                  <div class="input-group">
                    <input type="text" class="form-control" value="10" />
                    <div class="input-group-append">
                      <button type="button" class="btn btn-light">BTC</button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-3 col-md-6">
                <div class="form-group">
                  <label class="form-label float-left">Price For BTC</label>
                  <div class="input-group">
                    <input type="text" class="form-control" value="15,25,457" />
                    <div class="input-group-append">
                      <button type="button" class="btn btn-light">USD</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="float-right">
              <a class="btn btn-primary" href="javascript:;"
                ><i class="fa fa-refresh"></i> Transfer Now</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div> -->
<!-- row -->

<!-- row -->
<!-- <div class="row">
  <div class="col-md-12">
    <div class="card overflow-hidden">
      <div class="card-body">
        <div class="">
          <div class="d-flex justify-content-between">
            <h4 class="card-title mg-b-10">
              Crypto currencies marketing values
            </h4>
            <div class="ml-auto mr-2">
              <div ngbDropdown class="show">
                <a class="new" ngbDropdownToggle type="button">
                  <i class="fas fa-ellipsis-h text-muted"></i>
                </a>
                <div class="dropdown-menu-left" ngbDropdownMenu>
                  <a ngbDropdownItem href="javascript:;">Market Rules </a>
                  <a ngbDropdownItem href="javascript:;">Currencie Settings</a>
                </div>
              </div>
            </div>
          </div>
          <p class="tx-12 tx-gray-500 mb-2">
            A cryptocurrency is a new form of digital asset based on a network
            that is distributed across a large number of computers. This
            decentralized structure allows them to exist outside the control of
            governments and central authorities.<a href="javascript:;"
              >Learn more</a
            >
          </p>
        </div>
        <div class="table-responsive market-values">
          <table
            class="table text-nowrap text-md-nowrap table-striped table-hover mg-b-0"
          >
            <thead>
              <tr>
                <th>Name</th>
                <th>Icon</th>
                <th>Price</th>
                <th>% 24h</th>
                <th>Market Cap</th>
                <th>CMGR/Month</th>
                <th>Exchange</th>
              </tr>
            </thead>
            <tbody>
              <tr class="border-bottom" *ngFor="let data of dashboardTable">
                <td>{{ data.Name }}</td>
                <td><i class="{{ data.Icon }}"></i></td>
                <td>{{ data.Price }}</td>
                <td class="text-red">{{ data.h24 }}</td>
                <td class="">{{ data.MarketCap }}</td>
                <td>{{ data.CMGRMonth }}</td>
                <td>
                  <a href="javascript:;" class="btn btn-sm btn-success">{{
                    data.Exchange
                  }}</a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div> -->
<!-- /row -->
