import { Injectable } from '@angular/core';
import { ApexOptions } from 'apexcharts';
import { DataStorageService } from '../../services/data-storage.service';
export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  xaxis: ApexXAxis;
  stroke: ApexStroke;
  dataLabels: ApexDataLabels;
  yaxis: ApexYAxis;
  title: ApexTitleSubtitle;
  labels: string[];
  legend: ApexLegend;
  subtitle: ApexTitleSubtitle;
};
@Injectable({ providedIn: 'root' })
export class StockPricesData {
  dashboard1: ApexOptions = {
    series: [
      {
        data: [],
      },
    ],
  };
  public chartOptions: Partial<ChartOptions>;
  constructor(private dataStorage: DataStorageService) {}
  ngOnInit(): void {}
}
export let ApexdashboardData1: ApexOptions = {
  chart: {
    type: 'area',
    sparkline: {
      enabled: true,
    },
    height: 100,
  },
  stroke: {
    curve: 'smooth',
    width: 2,
  },
  colors: ['#5965f9'],
  tooltip: {},
};
export let ApexdashboardData2: ApexOptions = {
  chart: {
    type: 'area',
    sparkline: {
      enabled: true,
    },
    height: 100,
  },
  stroke: {
    curve: 'smooth',
    width: 2,
  },
  colors: ['#ff5959'],
  tooltip: {},
};
export let ApexdashboardData3: ApexOptions = {
  chart: {
    type: 'area',
    sparkline: {
      enabled: true,
    },
    height: 100,
  },
  stroke: {
    curve: 'smooth',
    width: 2,
  },
  colors: ['#0acf97'],
  tooltip: {},
};

export let dashboard2: ApexOptions = {
  series: [
    {
      data: [
        [0, 49.331065063219285],
        [1, 48.79814898366035],
        [2, 50.61793547911337],
        [3, 53.31696317779434],
        [4, 54.78560952831719],
      ],
    },
  ],
};
export let dashboard3: ApexOptions = {
  series: [
    {
      data: [
        [0, 53.08330533680049],
        [1, 50.33339517545416],
        [2, 49.4029746664779],
        [3, 47.791939081203566],
        [4, 49.09471219192674],
      ],
    },
  ],
};

export let dashboard4: ApexOptions = {
  series: [
    {
      data: [
        [0, 53.08330533680049],
        [1, 50.33339517545416],
        [2, 49.4029746664779],
        [3, 47.791939081203566],
        [4, 49.09471219192674],
      ],
    },
  ],
};
