<div class="my-auto page page-h">
  <div class="main-signin-wrapper">
    <div class="main-card-signin d-md-flex wd-100p">
      <div
        class="wd-md-50p login d-none d-md-block page-signin-style p-5 text-white"
      >
        <div class="my-auto authentication-pages">
          <div>
            <img
              src="../../assets/img/brand/iofs-logo.png"
              class="m-0 mb-4"
              alt="logo"
            />
            <h5 class="mb-4">First Stock Trading Service</h5>
            <p class="mb-5">
              Our service offerings ranges from trade executions, cscs creation,
              stocks analysis, portfolio management, economic/ financial
              analysis and advisory.
            </p>
            <a
              href="https://tradingservicewebsite-dev.azurewebsites.net/contact"
              class="btn btn-danger"
              target="_blank"
              >Contact Us</a
            >
          </div>
        </div>
      </div>
      <div class="p-5 wd-md-50p">
        <div class="main-signin-header">
          <h2>Welcome back!</h2>
          <h4>Please sign in to continue</h4>
          <ngb-alert type="danger" [dismissible]="false" *ngIf="error">
            <span class="alert-inner--icon"
              ><i class="fe fe-slash mr-1"></i
            ></span>
            <span class="alert-inner--text"> {{ error }}!</span>
          </ngb-alert>
          <ngb-alert
            type="success"
            [dismissible]="false"
            *ngIf="activationMessage"
          >
            <span class="alert-inner--icon"
              ><i class="fe fe-slash mr-1"></i
            ></span>
            <span class="alert-inner--text"> {{ activationMessage }}!</span>
          </ngb-alert>
          <div *ngIf="isLoading" class="text-center">
            <app-loader-spinner></app-loader-spinner>
          </div>
          <div *ngIf="!isLoggedOne" else otpVerify>
            <form
              [formGroup]="loginForm"
              (ngSubmit)="Submit()"
              *ngIf="!isLoading"
            >
              <div class="form-group">
                <label>Email</label>
                <input
                  class="form-control"
                  placeholder="Enter your email"
                  type="email"
                  formControlName="email"
                  required
                />
              </div>
              <div class="form-group">
                <label>Password</label>
                <input
                  class="form-control"
                  placeholder="Enter your password"
                  type="password"
                  formControlName="password"
                  required
                  minlength="8"
                />
              </div>
              <button
                class="btn btn-main-primary btn-block"
                [disabled]="!loginForm.valid"
              >
                Sign In
              </button>
            </form>
          </div>
          <app-verify-login *ngIf="isLoggedOne"></app-verify-login>
        </div>

        <div class="main-signin-footer mt-3 mg-t-5">
          <p><a routerLink="/auth/forgot-password">Forgot password?</a></p>
          <p>
            Don't have an account?
            <a routerLink="/register">Create an Account</a>
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
