import { Component, OnInit } from '@angular/core';
import { ApexOptions } from 'apexcharts';
import * as dashboardData from '../../../shared/data/dashboard/dashboard';
import * as chartData from '../../../shared/data/charts/apex_chart';
import {
  NgbCarousel,
  NgbCarouselConfig,
  NgbSlideEvent,
  NgbSlideEventSource,
} from '@ng-bootstrap/ng-bootstrap';
import { OwlOptions } from 'ngx-owl-carousel-o';
import PerfectScrollbar from 'perfect-scrollbar';
import { DashboardTableData } from 'src/app/shared/data/dashboard/dashboardTable';
import { DataStorageService } from 'src/app/shared/services/data-storage.service';
import { AuthService } from 'src/app/authentication/auth.service';
export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  xaxis: ApexXAxis;
  stroke: ApexStroke;
  dataLabels: ApexDataLabels;
  yaxis: ApexYAxis;
  title: ApexTitleSubtitle;
  labels: string[];
  legend: ApexLegend;
  subtitle: ApexTitleSubtitle;
};
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {
  public zenithOptions: Partial<ChartOptions>;
  public seplatOptions: Partial<ChartOptions>;
  public dangcemOptions: Partial<ChartOptions>;
  isLoadingStock = true;
  isLoadingZenith = true;
  status1: boolean = false;
  active = 1;
  public dashboardTable = DashboardTableData;
  first_name: string;
  customOptions: OwlOptions;
  constructor(
    private dataStorage: DataStorageService,
    private authService: AuthService,
    private stockPrices: dashboardData.StockPricesData
  ) {
    this.status1 = this.dataStorage.getUser().is_live == true ? true : false;
    this.zenithOptions = {
      series: [
        {
          name: 'STOCK ABC',
          data: [],
        },
      ],
      chart: {
        type: 'area',
        height: 250,
        zoom: {
          enabled: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: 'straight',
      },

      title: {
        text: 'Fundamental Analysis of Stocks',
        align: 'left',
      },
      subtitle: {
        text: 'Price Movements',
        align: 'left',
      },
      labels: [],
      xaxis: {
        type: 'datetime',
      },
      yaxis: {
        opposite: true,
      },
      legend: {
        horizontalAlign: 'left',
      },
    };
    this.dangcemOptions = {
      series: [
        {
          name: 'STOCK ABC',
          data: [],
        },
      ],
      chart: {
        type: 'area',
        height: 250,
        zoom: {
          enabled: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: 'straight',
      },

      title: {
        text: 'Fundamental Analysis of Stocks',
        align: 'left',
      },
      subtitle: {
        text: 'Price Movements',
        align: 'left',
      },
      labels: [],
      xaxis: {
        type: 'datetime',
      },
      yaxis: {
        opposite: true,
      },
      legend: {
        horizontalAlign: 'left',
      },
    };
    this.seplatOptions = {
      series: [
        {
          name: 'STOCK ABC',
          data: [],
        },
      ],
      chart: {
        type: 'area',
        height: 250,
        zoom: {
          enabled: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: 'straight',
      },

      title: {
        text: 'Fundamental Analysis of Stocks',
        align: 'left',
      },
      subtitle: {
        text: 'Price Movements',
        align: 'left',
      },
      labels: [],
      xaxis: {
        type: 'datetime',
      },
      yaxis: {
        opposite: true,
      },
      legend: {
        horizontalAlign: 'left',
      },
    };
  }
  select1Currency = [
    {
      id: 'group',
      title: 'Currency',
      currency: [
        { id: 'USD', title: 'USD' },
        { id: 'EUR', title: 'EUR' },
        { id: 'INR', title: 'INR' },
        { id: 'GDP', title: 'GDP' },
        { id: 'JPY', title: 'JPY' },
        { id: 'RUB', title: 'RUB' },
      ],
    },
  ];

  ngOnInit(): void {
    this.getStockPrices();
    this.getTopGainers();
    this.first_name = this.dataStorage.getUser().first_name;
    this.customOptions = {
      loop: true,
      autoplay: true,
      slideTransition: 'linear',
      autoplaySpeed: 4900,
      autoplayHoverPause: true,
      smartSpeed: 1000,
      center: true,
      margin: 12,
      dots: false,
      rewind: false,
      lazyLoad: false,
      nav: false,
      responsive: {
        0: {
          items: 1,
        },
        300: {
          items: 1.5,
        },

        400: {
          items: 2,
        },
        640: {
          items: 3,
        },
        768: {
          items: 3,
        },

        900: {
          items: 5,
        },
        1200: {
          items: 6,
        },
        1600: {
          items: 7,
        },
      },
    };
  }

  public RandomData = chartData.ApexRandomData;

  public dashboardD1 = dashboardData.ApexdashboardData1;
  public dashboardD2 = dashboardData.ApexdashboardData2;
  public dashboardD3 = dashboardData.ApexdashboardData3;

  public dashboard1 = this.stockPrices.dashboard1;

  public dashboard2 = dashboardData.dashboard2;
  public dashboard3 = dashboardData.dashboard3;

  // public RandomData = dashboardData.ApexRandomData;
  // public dashboard4 = dashboardData.dashboard4;
  topGainers = [];

  ngAfterViewInit() {
    const transcationScroll = document.querySelector('#transcation-scroll');
    const ps = new PerfectScrollbar(transcationScroll);
  }
  getTopGainers() {
    const data = {
      date: '01-01-2020',
      limit: '10',
      type: 'gainers',
    };
    this.dataStorage
      .authPost('admin/top-gainers-losers', data)
      .subscribe((result) => {
        this.isLoadingStock = false;
        if (result.success === true) {
          this.topGainers = result.data.gainers;
        }
      });
  }
  getStockPrices() {
    this.dataStorage
      .authGet('admin/stock/prices/' + 'ZENITHBANK')
      .subscribe((result) => {
        this.isLoadingZenith = false;
        this.zenithOptions.series = [
          {
            name: 'ZENITHBANK',
            data: result.data.year[0],
          },
        ];
        this.zenithOptions.labels = result.data.year[1];
      });
    this.dataStorage
      .authGet('admin/stock/prices/' + 'DANGCEM')
      .subscribe((result) => {
        this.dangcemOptions.series = [
          {
            name: 'DANGCEM',
            data: result.data.year[0],
          },
        ];
        this.dangcemOptions.labels = result.data.year[1];
      });
    this.dataStorage
      .authGet('admin/stock/prices/' + 'SEPLAT')
      .subscribe((result) => {
        this.seplatOptions.series = [
          {
            name: 'SEPLAT',
            data: result.data.year[0],
          },
        ];
        this.seplatOptions.labels = result.data.year[1];
      });
  }
  clickEvent1() {
    this.status1 = !this.status1;
    this.dataStorage.authGet('admin/toggle-mode').subscribe((result) => {});
  }
}
