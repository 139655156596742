<div class="main-body app sidebar-mini Light-mode" [ngClass]="mainSidebarOpen">
    <!-- Page -->
    <div class="page">

        <!-- main-sidebar -->
        <app-sidebar (mouseover)="hoverEffect($event)" (mouseout)="hoverEffect($event)"></app-sidebar> 
        <!-- <app-sidemenu></app-sidemenu> -->
        <!-- main-sidebar -->

        <!-- main-content -->
        <div class="main-content app-content">

            <!-- main-header -->
            <app-header></app-header>
            <!-- <div class="jumps-prevent"></div> -->
            <!-- /main-header -->
            
            <!-- container -->
            <div class="container-fluid"  (click)="clickonBody($event)">
                <router-outlet></router-outlet>
            </div>
            <!-- /Container -->
        </div>
        <!-- /main-content -->

        <!-- Sidebar-right-->
        <app-notification-sidebar></app-notification-sidebar>
        <!--/Sidebar-right-->  

        <!-- Footer opened -->
        <app-footer></app-footer>
        <!-- Footer closed -->
        <app-tap-to-top></app-tap-to-top>
    </div>
    <!-- End Page -->
</div>
