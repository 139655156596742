<!-- Footer opened -->
<div class="main-footer ht-40">
  <div class="container-fluid pd-t-0-f ht-100p">
    <span
      >Copyright © {{ fullYear }}
      <a href="https://investment-one.com/stockbrokers/" target="_blank"
        >Investment One Stock Broking</a
      >
      All rights reserved.</span
    >
  </div>
</div>
<!-- Footer closed -->
