<!DOCTYPE html>
<html lang="zxx">
  <head>
    <!-- Required meta tags -->
    <meta charset="utf-8" />
    <meta
      name="viewport"
      content="width=device-width, initial-scale=1, shrink-to-fit=no"
    />

    <!-- Links Of CSS File -->

    <!-- Favicon -->
    <link
      rel="icon"
      type="image/png"
      href="../../../assets/images/favicon.png"
    />
    <!-- Title -->
    <title>Bitr - Banking & Payment Processing HTML Template</title>
  </head>

  <body>
    <!-- Start Preloader Area -->
    <div class="preloader" id="loader-style">
      <div class="preloader-wrap">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>
    <!-- End Preloader Area -->

    <!-- Start Navbar Area -->
    <div class="navbar-fixed fixed-top">
      <div class="navbar-area style-three">
        <div class="mobile-responsive-nav">
          <div class="container">
            <div class="mobile-responsive-menu">
              <div class="logo">
                <a href="index.html">
                  <img src="assets/images/logo.png" alt="logo" />
                </a>
              </div>
            </div>
          </div>
        </div>

        <div class="desktop-nav style-three">
          <div class="container-fluid">
            <nav class="navbar navbar-expand-md navbar-light">
              <a class="navbar-brand" href="index.html">
                <img src="assets/images/white-logo.png" alt="logo" />
              </a>

              <div
                class="collapse navbar-collapse mean-menu"
                id="navbarSupportedContent"
              >
                <ul class="navbar-nav ms-auto">
                  <li class="nav-item">
                    <a href="#" class="nav-link dropdown-toggle active">
                      Home
                    </a>

                    <ul class="dropdown-menu">
                      <li class="nav-item">
                        <a href="index.html" class="nav-link">Home One</a>
                      </li>
                      <li class="nav-item">
                        <a href="index-2.html" class="nav-link">Home Two</a>
                      </li>
                      <li class="nav-item">
                        <a href="index-3.html" class="nav-link active"
                          >Home Three</a
                        >
                      </li>
                    </ul>
                  </li>

                  <li class="nav-item">
                    <a href="#" class="nav-link dropdown-toggle"> Pages </a>

                    <ul class="dropdown-menu">
                      <li class="nav-item">
                        <a href="about-us.html" class="nav-link">About Us</a>
                      </li>

                      <li class="nav-item">
                        <a href="all-country.html" class="nav-link"
                          >All Country</a
                        >
                      </li>

                      <li class="nav-item">
                        <a href="faq.html" class="nav-link">FAQ</a>
                      </li>

                      <li class="nav-item">
                        <a href="login.html" class="nav-link">Login</a>
                      </li>

                      <li class="nav-item">
                        <a href="register.html" class="nav-link">Register</a>
                      </li>

                      <li class="nav-item">
                        <a href="privacy-policy.html" class="nav-link"
                          >Privacy Policy</a
                        >
                      </li>

                      <li class="nav-item">
                        <a href="terms-conditions.html" class="nav-link"
                          >Terms And Conditions</a
                        >
                      </li>

                      <li class="nav-item">
                        <a href="coming-soon.html" class="nav-link"
                          >Coming Soon</a
                        >
                      </li>

                      <li class="nav-item">
                        <a href="404.html" class="nav-link">404 Page</a>
                      </li>
                    </ul>
                  </li>

                  <li class="nav-item">
                    <a href="money-transfer.html" class="nav-link"
                      >Money Transfer</a
                    >
                  </li>

                  <li class="nav-item">
                    <a href="#" class="nav-link dropdown-toggle"> Features </a>

                    <ul class="dropdown-menu">
                      <li class="nav-item">
                        <a href="features.html" class="nav-link">Features</a>
                      </li>
                      <li class="nav-item">
                        <a href="feature-details.html" class="nav-link"
                          >Feature Details</a
                        >
                      </li>
                    </ul>
                  </li>

                  <li class="nav-item">
                    <a href="#" class="nav-link dropdown-toggle"> Blog </a>

                    <ul class="dropdown-menu">
                      <li class="nav-item">
                        <a href="blog.html" class="nav-link">Blog</a>
                      </li>
                      <li class="nav-item">
                        <a href="blog-details.html" class="nav-link"
                          >Blog Details</a
                        >
                      </li>
                    </ul>
                  </li>

                  <li class="nav-item">
                    <a href="contact-us.html" class="nav-link">Contact Us</a>
                  </li>
                </ul>

                <div class="others-options">
                  <ul>
                    <li>
                      <a href="login.html" class="default-btn login"> Login </a>
                    </li>
                    <li>
                      <a href="register.html" class="default-btn"> Register </a>
                    </li>
                  </ul>
                </div>
              </div>
            </nav>
          </div>
        </div>

        <div class="others-option-for-responsive">
          <div class="container">
            <div class="dot-menu">
              <div class="inner">
                <div class="circle circle-one"></div>
                <div class="circle circle-two"></div>
                <div class="circle circle-three"></div>
              </div>
            </div>

            <div class="container">
              <div class="option-inner">
                <div
                  class="others-options justify-content-center d-flex align-items-center"
                >
                  <ul>
                    <li>
                      <a href="login.html" class="default-btn login"> Login </a>
                    </li>
                    <li>
                      <a href="register.html" class="default-btn"> Register </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- End Navbar Area -->

    <!-- Start Banner Area -->
    <div class="banner-area bg-3 ptb-100 bg-color-f5f5f5">
      <div class="container-fluid">
        <div class="row align-items-center">
          <div class="banner-content style-three">
            <div class="row align-items-center">
              <div class="col-lg-6">
                <h1 class="wow fadeInUp delay-0-2s">
                  Follow A Better Way To Send Money
                </h1>
                <p class="wow fadeInUp delay-0-6s">
                  Connect your money to your friends & family from anywhere,
                  anytime regardless any delay. Lorem ipsummet, aliquer dolor
                  sit amet, consectetur adipiscing elit. Viverra natoque
                  facilisi odio.
                </p>

                <div class="banner-btn wow fadeInUp delay-0-8s">
                  <a href="register.html" class="default-btn"> Get Started </a>
                  <a href="money-transfer.html" class="default-btn active">
                    Transfer Money Now
                  </a>
                </div>

                <div class="partner-slide owl-carousel owl-theme">
                  <div class="partner-item">
                    <img
                      src="assets/images/partners/partner-6.png"
                      alt="Image"
                    />
                  </div>
                  <div class="partner-item">
                    <img
                      src="assets/images/partners/partner-7.png"
                      alt="Image"
                    />
                  </div>
                  <div class="partner-item">
                    <img
                      src="assets/images/partners/partner-8.png"
                      alt="Image"
                    />
                  </div>
                  <div class="partner-item">
                    <img
                      src="assets/images/partners/partner-9.png"
                      alt="Image"
                    />
                  </div>
                </div>
              </div>

              <div class="col-lg-6">
                <div class="banner-img three wow fadeInUp delay-0-2s">
                  <img src="assets/images/banner/card.png" alt="Image" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- End Banner Area -->

    <!-- Start Why Bitr Best Area -->
    <div class="why-bitr-best-area pt-100 pb-70">
      <div class="container">
        <div class="section-title">
          <span class="top-title">Why Bitr Best</span>
          <h2>A Technology-First Approach</h2>
          <p>
            Connect your money to your friends & family from anywhere, anytime
            regardless any delay. Lorem ipsum Nullana integer sagittis,
            eleifend. met, aliquere.
          </p>
        </div>

        <div class="row justify-content-center wow fadeInUp delay-0-2s">
          <div class="col-lg-3 col-sm-6">
            <div class="single-why-bitr">
              <i class="flaticon-analytics-2"></i>
              <h3>Close To The Metal</h3>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mattis
                mi suscipit bibendum. <a href="features.html">Learn More</a>
              </p>
            </div>
          </div>

          <div class="col-lg-3 col-sm-6">
            <div class="single-why-bitr wow fadeInUp delay-0-4s">
              <i class="flaticon-target"></i>
              <h3>Fastpace Platform</h3>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mattis
                mi suscipit bibendum. <a href="features.html">Learn More</a>
              </p>
            </div>
          </div>

          <div class="col-lg-3 col-sm-6">
            <div class="single-why-bitr wow fadeInUp delay-0-6s">
              <i class="flaticon-shield"></i>
              <h3>Tested Reliability</h3>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mattis
                mi suscipit bibendum. <a href="features.html">Learn More</a>
              </p>
            </div>
          </div>

          <div class="col-lg-3 col-sm-6">
            <div class="single-why-bitr wow fadeInUp delay-0-8s">
              <i class="flaticon-profits"></i>
              <h3>Intelligent Optimizations</h3>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mattis
                mi suscipit bibendum. <a href="features.html">Learn More</a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- End Why Bitr Best Area -->

    <!-- Start Unified platform Area -->
    <div class="unified-platform-area bg-color-fff9f8 ptb-100">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-lg-6">
            <div class="unified-platform-content wow fadeInLeft delay-0-4s">
              <div class="mb-30">
                <span class="top-title">Designed For Developers</span>
                <h2>The World’s Most Powerful And Easy-to-use Apis</h2>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Mattis mi suscipit bibendum pellentesque ut condimentum
                  consectetur eleifend velit. Ut volutpat risus aliquet non
                  commodo commodo habitant lacus dolor sit amet, consectetur
                  adipiscing.
                </p>
              </div>

              <div class="row">
                <div class="col-lg-6 col-sm-6">
                  <div class="single-unified-platform">
                    <i class="flaticon-wallet"></i>
                    <h3>Tools For Every Stack</h3>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Nullana integer sagittis, eleifend ipsum dolor sit dolor.
                    </p>
                  </div>
                </div>
                <div class="col-lg-6 col-sm-6">
                  <div class="single-unified-platform">
                    <i class="flaticon-id-card"></i>
                    <h3>Prebuilt Integrations</h3>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Nullana integer sagittis, eleifend ipsum dolor sit dolor.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-6">
            <div class="unified-img three wow fadeInRight delay-0-4s ml-50">
              <img src="assets/images/unified-img-3.png" alt="Image" />
              <img
                src="assets/images/unified-shape-1.png"
                class="unified-shape-1"
                alt="Image"
              />
              <img
                src="assets/images/unified-shape-2.png"
                class="unified-shape-2"
                alt="Image"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- End Unified platform Area -->

    <!-- Start Unified platform Area -->
    <div class="unified-platform-area ptb-100">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-lg-6">
            <div class="unified-img two wow fadeInLeft delay-0-4s mr-50">
              <img src="assets/images/unified-img-2.png" alt="Image" />
            </div>
          </div>

          <div class="col-lg-6">
            <div class="unified-platform-content wow fadeInRight delay-0-4s">
              <div class="mb-30">
                <span class="top-title">Unified Platform</span>
                <h2>A Fully Integrated Suite Of Every Product Payments</h2>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Mattis mi suscipit bibendum pellentesque ut condimentum
                  consectetur eleifend velit. Ut volutpat risus aliquet non
                  commodo commodo habitant lacus.
                </p>
              </div>

              <div class="row">
                <div class="col-lg-6 col-sm-6">
                  <div class="single-unified-platform">
                    <i class="flaticon-wallet"></i>
                    <h3>Split The Necessities</h3>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Nullana integer sagittis, eleifend ipsum dolor sit dolor.
                    </p>
                  </div>
                </div>
                <div class="col-lg-6 col-sm-6">
                  <div class="single-unified-platform">
                    <i class="flaticon-id-card"></i>
                    <h3>Pay For Business</h3>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Nullana integer sagittis, eleifend ipsum dolor sit dolor.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- End Unified platform Area -->

    <!-- Start Fast & Secure Area -->
    <div class="fast-secure-area bg-color-fff9f8 ptb-100">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-lg-6">
            <div class="fast-secure-content">
              <span class="top-title">Fast & Secure</span>
              <h2>A Fast And Secure Way To Send Money On The Go From The Us</h2>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Amet
                sed arcu morbi id quis. Quis odio tortor at pharetra, sit vel
                urna, nec egestas.
              </p>

              <div class="row">
                <div class="col-lg-6 col-sm-6">
                  <ul>
                    <li>
                      <i class="flaticon-check-mark"></i>
                      Free Plan Available
                    </li>
                    <li>
                      <i class="flaticon-check-mark"></i>
                      Debit Mastercard Included
                    </li>
                    <li>
                      <i class="flaticon-check-mark"></i>
                      Commitment Free
                    </li>
                  </ul>
                </div>
                <div class="col-lg-6 col-sm-6">
                  <ul>
                    <li>
                      <i class="flaticon-check-mark"></i>
                      Full Data Privacy Compliance
                    </li>
                    <li>
                      <i class="flaticon-check-mark"></i>
                      100% Transparent Costs
                    </li>
                    <li>
                      <i class="flaticon-check-mark"></i>
                      Real-time Spending Overview
                    </li>
                  </ul>
                </div>
              </div>

              <a href="features.html" class="default-btn"> Try Now </a>
            </div>
          </div>

          <div class="col-lg-6">
            <div class="fast-mgs">
              <img src="assets/images/download.png" alt="image" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- End Fast & Secure Area -->

    <!-- Start Support Area -->
    <div class="support-area bg-color-fbfbfb pt-100 pb-70">
      <div class="container">
        <div class="section-title">
          <span class="top-title">Key Features</span>
          <h2>How We Support You</h2>
          <p>
            Connect your money to your friends & family from anywhere, anytime
            regardless any delay. Lorem ipsum Nullana integer sagittis,
            eleifend. met, aliquere.
          </p>
        </div>
        <div class="row">
          <div class="col-lg-3 col-sm-6">
            <div class="single-support wow fadeInUp delay-0-2s">
              <img src="assets/images/support/support-1.png" alt="Image" />
              <h3>
                <a href="faq.html">Send Money</a>
              </h3>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullana
                integer sagittis, eleifend consectetur adipiscing.
              </p>
              <a href="features.html" class="read-more">View Details</a>
            </div>
          </div>

          <div class="col-lg-3 col-sm-6">
            <div class="single-support wow fadeInUp delay-0-4s">
              <img src="assets/images/support/support-2.png" alt="Image" />
              <h3>
                <a href="faq.html">Interest Money</a>
              </h3>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullana
                integer sagittis, eleifend consectetur adipiscing.
              </p>
              <a href="features.html" class="read-more">View Details</a>
            </div>
          </div>

          <div class="col-lg-3 col-sm-6">
            <div class="single-support wow fadeInUp delay-0-6s">
              <img src="assets/images/support/support-3.png" alt="Image" />
              <h3>
                <a href="faq.html">Bank Transfer</a>
              </h3>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullana
                integer sagittis, eleifend consectetur adipiscing.
              </p>
              <a href="features.html" class="read-more">View Details</a>
            </div>
          </div>

          <div class="col-lg-3 col-sm-6">
            <div class="single-support wow fadeInUp delay-0-8s">
              <img src="assets/images/support/support-4.png" alt="Image" />
              <h3>
                <a href="faq.html">Invest Money</a>
              </h3>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullana
                integer sagittis, eleifend consectetur adipiscing.
              </p>
              <a href="features.html" class="read-more">View Details</a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- End Support Area -->

    <!-- Start Counter Area -->
    <div class="counter-area bg-color-3c312b pt-100 pb-70">
      <div class="container">
        <div class="counter-shape">
          <div class="row">
            <div class="col-lg-3 col-sm-6">
              <div class="single-counter-item wow fadeInUp delay-0-2s">
                <h2>
                  <span class="odometer" data-count="250">00</span>
                  <span class="target">M</span>
                </h2>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing.</p>
              </div>
            </div>

            <div class="col-lg-3 col-sm-6">
              <div class="single-counter-item wow fadeInUp delay-0-4s">
                <h2>
                  <span class="odometer" data-count="100">00</span>
                  <span class="target">K</span>
                </h2>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing.</p>
              </div>
            </div>

            <div class="col-lg-3 col-sm-6">
              <div class="single-counter-item wow fadeInUp delay-0-6s">
                <h2>
                  <span class="odometer" data-count="100%">00</span>
                  <span class="target">%</span>
                </h2>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing.</p>
              </div>
            </div>

            <div class="col-lg-3 col-sm-6">
              <div class="single-counter-item wow fadeInUp delay-0-8s">
                <h2>
                  <span class="odometer" data-count="58">00</span>
                  <span class="target">+</span>
                </h2>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing.</p>
              </div>
            </div>
          </div>

          <img
            src="assets/images/counter-shape.png"
            class="counter-shape"
            alt="Image"
          />
        </div>
      </div>
    </div>
    <!-- End Counter Area -->

    <!-- Start Download Area -->
    <div class="download-area ptb-100">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-lg-6">
            <div class="download-img wow fadeInLeft delay-0-4s">
              <img src="assets/images/download.png" alt="Image" />
            </div>
          </div>

          <div class="col-lg-6">
            <div class="download-content wow fadeInRight delay-0-4s">
              <span class="top-title">Download App</span>
              <h2>Download Our Mobile App To Get All Features</h2>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mattis
                mi suscipit bibendum pellentesque ut condimentum consectetur
                eleifend velit. Ut volutpat risus aliquet non commodo commodo
                habitant lacus.
              </p>

              <div class="downonlod-btn">
                <a
                  href="https://play.google.com/store/apps"
                  target="_blank"
                  class="store-btn"
                >
                  <i class="flaticon-playstore"></i>
                  <span>GET IT ON</span>
                  <p>Google Play</p>
                </a>
                <a
                  href="https://www.apple.com/store"
                  target="_blank"
                  class="store-btn active"
                >
                  <i class="flaticon-apple"></i>
                  <span>GET IT ON</span>
                  <p>Apple Store</p>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- End Download Area -->

    <!-- Start Steps Area -->
    <div class="steps-area bg-color-fff9f8 pt-100 pb-70">
      <div class="container">
        <div class="section-title">
          <span class="top-title">Working Process</span>
          <h2>Open An Account In Easy 4 Steps</h2>
          <p>
            Connect your money to your friends & family from anywhere, anytime
            regardless any delay. Lorem ipsum Nullana integer sagittis,
            eleifend. met, aliquere.
          </p>
        </div>
        <div class="row">
          <div class="col-lg-3 col-sm-6">
            <div class="single-steps wow fadeInUp delay-0-2s">
              <img src="assets/images/steps/steps-1.png" alt="Image" />
              <h3>1.Open Account</h3>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullana
                integer sagittis, eleifend consectetur adipiscing.
              </p>
            </div>
          </div>

          <div class="col-lg-3 col-sm-6">
            <div class="single-steps wow fadeInUp delay-0-4s">
              <img src="assets/images/steps/steps-2.png" alt="Image" />
              <h3>2.Verify Identity</h3>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullana
                integer sagittis, eleifend consectetur adipiscing.
              </p>
            </div>
          </div>

          <div class="col-lg-3 col-sm-6">
            <div class="single-steps wow fadeInUp delay-0-6s">
              <img src="assets/images/steps/steps-3.png" alt="Image" />
              <h3>3.Connect Your Account</h3>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullana
                integer sagittis, eleifend consectetur adipiscing.
              </p>
            </div>
          </div>

          <div class="col-lg-3 col-sm-6">
            <div class="single-steps wow fadeInUp delay-0-8s">
              <img src="assets/images/steps/steps-4.png" alt="Image" />
              <h3>4.Send Money</h3>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullana
                integer sagittis, eleifend consectetur adipiscing.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- End Steps Area -->

    <!-- Start Customers Area -->
    <div class="customers-area ptb-100">
      <div class="container">
        <div class="customers-bg">
          <div class="section-title">
            <span class="top-title">Testimonial</span>
            <h2>Trusted Customers Feedback</h2>
            <p>
              Connect your money to your friends & family from anywhere, anytime
              regardless any delay. Lorem ipsum Nullana integer sagittis,
              eleifend. met, aliquere.
            </p>
          </div>

          <div class="customers-slide owl-carousel owl-theme">
            <div class="customers-single-item">
              <i class="flaticon-left-quote quote"></i>
              <p>
                I’m really honoured that I found Bitr in my danger time.It is
                our dedication that ipsum Nullana integer sagittis, eleifend.
                met, aliquere.defines us. We are continuously improving our
                craft in order.
              </p>
              <ul>
                <li>
                  <i class="flaticon-star"></i>
                </li>
                <li>
                  <i class="flaticon-star"></i>
                </li>
                <li>
                  <i class="flaticon-star"></i>
                </li>
                <li>
                  <i class="flaticon-star"></i>
                </li>
                <li>
                  <i class="flaticon-star"></i>
                </li>
              </ul>

              <h3>Jane Ronan</h3>
              <span>Ceo Of S.Software</span>
            </div>

            <div class="customers-single-item">
              <i class="flaticon-left-quote quote"></i>
              <p>
                I’m really honoured that I found Bitr in my danger time.It is
                our dedication that ipsum Nullana integer sagittis, eleifend.
                met, aliquere.defines us. We are continuously improving our
                craft in order.
              </p>
              <ul>
                <li>
                  <i class="flaticon-star"></i>
                </li>
                <li>
                  <i class="flaticon-star"></i>
                </li>
                <li>
                  <i class="flaticon-star"></i>
                </li>
                <li>
                  <i class="flaticon-star"></i>
                </li>
                <li>
                  <i class="flaticon-star"></i>
                </li>
              </ul>

              <h3>Alex Dew</h3>
              <span>Ceo Of Developer</span>
            </div>

            <div class="customers-single-item">
              <i class="flaticon-left-quote quote"></i>
              <p>
                I’m really honoured that I found Bitr in my danger time.It is
                our dedication that ipsum Nullana integer sagittis, eleifend.
                met, aliquere.defines us. We are continuously improving our
                craft in order.
              </p>
              <ul>
                <li>
                  <i class="flaticon-star"></i>
                </li>
                <li>
                  <i class="flaticon-star"></i>
                </li>
                <li>
                  <i class="flaticon-star"></i>
                </li>
                <li>
                  <i class="flaticon-star"></i>
                </li>
                <li>
                  <i class="flaticon-star"></i>
                </li>
              </ul>

              <h3>Anne Mari</h3>
              <span>Ceo Of Web</span>
            </div>
          </div>

          <img
            src="assets/images/counter-shape.png"
            class="counter-shape"
            alt="Image"
          />
        </div>
      </div>

      <div class="lines">
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
      </div>
    </div>
    <!-- End Customers Area -->

    <!-- Start Btro Info Area -->
    <div class="btro-info-area bg-color-3c312b ptb-100">
      <div class="container">
        <div class="btro-info-content">
          <p>
            Your <span>Bitr</span> account is your key to unlocking a universe
            of opportunities. Whether making international payments, receiving
            funds, managing your digital business, or accessing capital, Bitr
            opens your business up to the world. Lorem ipsummet, aliquer dolor
            sit amet. <a href="about-us.html">Learn More</a>
          </p>
        </div>
      </div>

      <img src="assets/images/info-shape-1.png" class="shape-1" alt="Image" />
      <img src="assets/images/info-shape-2.png" class="shape-2" alt="Image" />
    </div>
    <!-- End Btro Info Area -->

    <!-- End Country Area -->
    <div class="country-area ptb-100">
      <div class="container">
        <div class="section-title">
          <span class="top-title">Countries We Cover</span>
          <h2>Send Money To Over 130 Countries Worldwide</h2>
          <p>
            Connect your money to your friends & family from anywhere, anytime
            regardless any delay. Lorem ipsum Nullana integer sagittis,
            eleifend. met, aliquere.
          </p>
        </div>

        <div class="row justify-content-center">
          <div class="col-lg-3 col-sm-6">
            <div class="single-country wow fadeInLeft delay-0-2s">
              <h3>
                <img src="assets/images/flag/flag-3.png" alt="Image" />
                British Pound
              </h3>
              <p>
                Lorem ipsum dolor sit amet, Nullana integer sagittis, eleifend.
              </p>
              <a href="money-transfer.html" class="read-more">
                Send Money
                <i class="ri-arrow-right-s-line"></i>
              </a>
            </div>
          </div>

          <div class="col-lg-3 col-sm-6">
            <div class="single-country wow fadeInLeft delay-0-4s">
              <h3>
                <img src="assets/images/flag/flag-4.png" alt="Image" />
                British Pound
              </h3>
              <p>
                Lorem ipsum dolor sit amet, Nullana integer sagittis, eleifend.
              </p>
              <a href="money-transfer.html" class="read-more">
                Send Money
                <i class="ri-arrow-right-s-line"></i>
              </a>
            </div>
          </div>

          <div class="col-lg-3 col-sm-6">
            <div class="single-country wow fadeInLeft delay-0-6s">
              <h3>
                <img src="assets/images/flag/flag-5.png" alt="Image" />
                Euro
              </h3>
              <p>
                Lorem ipsum dolor sit amet, Nullana integer sagittis, eleifend.
              </p>
              <a href="money-transfer.html" class="read-more">
                Send Money
                <i class="ri-arrow-right-s-line"></i>
              </a>
            </div>
          </div>

          <div class="col-lg-3 col-sm-6">
            <div class="single-country wow fadeInLeft delay-0-8s">
              <h3>
                <img src="assets/images/flag/flag-6.png" alt="Image" />
                German Mark
              </h3>
              <p>
                Lorem ipsum dolor sit amet, Nullana integer sagittis, eleifend.
              </p>
              <a href="money-transfer.html" class="read-more">
                Send Money
                <i class="ri-arrow-right-s-line"></i>
              </a>
            </div>
          </div>

          <div class="col-lg-3 col-sm-6">
            <div class="single-country wow fadeInLeft delay-0-2s">
              <h3>
                <img src="assets/images/flag/flag-7.png" alt="Image" />
                Sri Lankan Rupee
              </h3>
              <p>
                Lorem ipsum dolor sit amet, Nullana integer sagittis, eleifend.
              </p>
              <a href="money-transfer.html" class="read-more">
                Send Money
                <i class="ri-arrow-right-s-line"></i>
              </a>
            </div>
          </div>

          <div class="col-lg-3 col-sm-6">
            <div class="single-country wow fadeInLeft delay-0-4s">
              <h3>
                <img src="assets/images/flag/flag-8.png" alt="Image" />
                Norwegian Krone
              </h3>
              <p>
                Lorem ipsum dolor sit amet, Nullana integer sagittis, eleifend.
              </p>
              <a href="money-transfer.html" class="read-more">
                Send Money
                <i class="ri-arrow-right-s-line"></i>
              </a>
            </div>
          </div>

          <div class="col-lg-3 col-sm-6">
            <div class="single-country wow fadeInLeft delay-0-6s">
              <h3>
                <img src="assets/images/flag/flag-9.png" alt="Image" />
                Turkish Lira
              </h3>
              <p>
                Lorem ipsum dolor sit amet, Nullana integer sagittis, eleifend.
              </p>
              <a href="money-transfer.html" class="read-more">
                Send Money
                <i class="ri-arrow-right-s-line"></i>
              </a>
            </div>
          </div>

          <div class="col-lg-3 col-sm-6">
            <div class="single-country wow fadeInLeft delay-0-8s">
              <h3>
                <img src="assets/images/flag/flag-10.png" alt="Image" />
                Canadian Dollar
              </h3>
              <p>
                Lorem ipsum dolor sit amet, Nullana integer sagittis, eleifend.
              </p>
              <a href="money-transfer.html" class="read-more">
                Send Money
                <i class="ri-arrow-right-s-line"></i>
              </a>
            </div>
          </div>

          <div class="col-12">
            <div class="all-country">
              <p>
                Bitr opens your business up to the world. Lorem ipsummet,
                aliquer dolor sit amet.
                <a href="all-country.html">View All Countries</a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- End Country Area -->

    <!-- Start Blog Area -->
    <div class="blog-area pt-100 pb-70 bg-color-fbfbfb">
      <div class="container">
        <div class="section-title">
          <span class="top-title">Our Blog</span>
          <h2>Read Latest Article</h2>
          <p>
            Connect your money to your friends & family from anywhere, anytime
            regardless any delay. Lorem ipsum Nullana integer sagittis,
            eleifend. met, aliquere.
          </p>
        </div>

        <div class="blog-slide owl-carousel owl-theme">
          <div class="single-blog-item wow fadeInUp delay-0-2s">
            <a href="blog-details.html">
              <img src="assets/images/blog/blog-1.jpg" alt="Image" />
            </a>

            <div class="blog-content">
              <ul>
                <li>
                  <i class="flaticon-calendar"></i>
                  19th Aug, 2021
                </li>
                <li>
                  <i class="flaticon-user"></i>
                  <a href="blog-details.html">By Admin</a>
                </li>
              </ul>
              <h3>
                <a href="blog-details.html">
                  Duis Nonummy Socios Mattis Tempus Penatibus...
                </a>
              </h3>
            </div>
          </div>

          <div class="single-blog-item wow fadeInUp delay-0-4s">
            <a href="blog-details.html">
              <img src="assets/images/blog/blog-2.jpg" alt="Image" />
            </a>

            <div class="blog-content">
              <ul>
                <li>
                  <i class="flaticon-calendar"></i>
                  20th Aug, 2021
                </li>
                <li>
                  <i class="flaticon-user"></i>
                  <a href="blog-details.html">By Admin</a>
                </li>
              </ul>
              <h3>
                <a href="blog-details.html">
                  The Ultimate Guide for The Freelancers Payment...
                </a>
              </h3>
            </div>
          </div>

          <div class="single-blog-item wow fadeInUp delay-0-8s">
            <a href="blog-details.html">
              <img src="assets/images/blog/blog-3.jpg" alt="Image" />
            </a>

            <div class="blog-content">
              <ul>
                <li>
                  <i class="flaticon-calendar"></i>
                  21th Aug, 2021
                </li>
                <li>
                  <i class="flaticon-user"></i>
                  <a href="blog-details.html">By Admin</a>
                </li>
              </ul>
              <h3>
                <a href="blog-details.html">
                  One Giant Leap: The Growth of eCommerce Amidst...
                </a>
              </h3>
            </div>
          </div>

          <div class="single-blog-item wow fadeInUp delay-0-4s">
            <a href="blog-details.html">
              <img src="assets/images/blog/blog-4.jpg" alt="Image" />
            </a>

            <div class="blog-content">
              <ul>
                <li>
                  <i class="flaticon-calendar"></i>
                  20th Aug, 2021
                </li>
                <li>
                  <i class="flaticon-user"></i>
                  <a href="blog-details.html">By Admin</a>
                </li>
              </ul>
              <h3>
                <a href="blog-details.html">
                  One Giant Leap: The Growth Of Ecommerce Amidst...
                </a>
              </h3>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- End Blog Area -->

    <!-- Start Footer Area -->
    <div class="footer-area pt-100 pb-70 bg-color-0f0e0e">
      <div class="container">
        <div class="row">
          <div class="col-lg-3 col-sm-6">
            <div class="single-widget">
              <div class="footer-logo">
                <a href="index.html">
                  <img src="assets/images/white-logo.png" alt="Image" />
                </a>
              </div>
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Sit
                odit ex laboriosam veritatis. Dolor sit optio maxime
              </p>

              <ul class="social-link">
                <li>
                  <span>Follow Us:</span>
                </li>
                <li>
                  <a href="https://facebook.com/" target="_blank">
                    <i class="flaticon-facebook"></i>
                  </a>
                </li>
                <li>
                  <a href="https://instagram.com/" target="_blank">
                    <i class="flaticon-instagram"></i>
                  </a>
                </li>
                <li>
                  <a href="https://twitter.com/" target="_blank">
                    <i class="flaticon-twitter"></i>
                  </a>
                </li>
                <li>
                  <a href="https://linkedin.com/" target="_blank">
                    <i class="flaticon-linkedin"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>

          <div class="col-lg-3 col-sm-6">
            <div class="single-widget ml-30">
              <h3>Quick Link</h3>

              <ul class="use-full-link">
                <li>
                  <a href="about-us.html">About Us</a>
                </li>
                <li>
                  <a href="features.html">Features</a>
                </li>
                <li>
                  <a href="feature-details.html">Feature Details</a>
                </li>
                <li>
                  <a href="money-transfer.html">Money Transfer</a>
                </li>
              </ul>
            </div>
          </div>

          <div class="col-lg-3 col-sm-6">
            <div class="single-widget ml-30">
              <h3>Help Center</h3>

              <ul class="use-full-link">
                <li>
                  <a href="contact-us.html">Contact Us</a>
                </li>
                <li>
                  <a href="faq.html">Get Help</a>
                </li>
                <li>
                  <a href="privacy-policy.html">Privacy Policy</a>
                </li>
                <li>
                  <a href="terms-conditions.html">Terms & Conditions</a>
                </li>
              </ul>
            </div>
          </div>

          <div class="col-lg-3 col-sm-6">
            <div class="single-widget">
              <h3>Contact Info</h3>

              <ul class="address-link">
                <li>
                  <i class="flaticon-phone-call"></i>
                  <span>Call Us:</span>
                  <a href="tel:+(323)-750-1234">+(323) 750-1234</a>
                </li>
                <li>
                  <i class="flaticon-email"></i>
                  <span>Address:</span>
                  +7011 Vermont Ave, Los Angeles, CA 90044
                </li>
                <li>
                  <i class="flaticon-phone-call"></i>
                  <span>Mail Us:</span>
                  <a href="mailto:hello@bitr.com">hello@bitr.com</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- End Footer Area -->

    <!-- Start CopyRight Area -->
    <div class="copy-right-area bg-color-1e1d1d">
      <div class="container">
        <div class="copy-right-content">
          <p>
            © <span id="year"></span> BITR Theme, All rights reserved by
            <a href="https://envytheme.com/" target="blank">EnvyTheme</a>
          </p>
        </div>
      </div>
    </div>
    <!-- End CopyRight Area -->

    <!-- Start Go Top Area -->
    <div class="go-top">
      <i class="ri-arrow-up-s-fill"></i>
      <i class="ri-arrow-up-s-fill"></i>
    </div>
    <!-- End Go Top Area -->

    <!-- Links of JS File -->
    <script src="assets/js/jquery.min.js"></script>
    <script src="assets/js/bootstrap.bundle.min.js"></script>
    <script src="assets/js/meanmenu.min.js"></script>
    <script src="assets/js/owl.carousel.min.js"></script>
    <script src="assets/js/wow.js"></script>
    <script src="assets/js/appear.min.js"></script>
    <script src="assets/js/odometer.min.js"></script>
    <script src="assets/js/form-validator.min.js"></script>
    <script src="assets/js/contact-form-script.js"></script>
    <script src="assets/js/custom.js"></script>
  </body>
</html>
