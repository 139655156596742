import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '../auth.service';
@Component({
  selector: 'app-verify-login',
  templateUrl: './verify-login.component.html',
  styleUrls: ['./verify-login.component.scss'],
})
export class VerifyLoginComponent implements OnInit {
  verifyForm: FormGroup;
  error = '';
  isLoading = false;
  constructor(
    private formBuilder: FormBuilder,
    public router: Router,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    this.verifyForm = this.formBuilder.group({
      otp: ['', Validators.required],
    });
  }
  verifyOtp() {
    if (!this.verifyForm.valid) {
      return null;
    }
    const otp = this.verifyForm.controls['otp'].value;
    this.isLoading = true;
    this.authService.verify_otp(otp).subscribe(
      (resData) => {
        this.isLoading = false;

        if (resData.status === 200) {
          if (+resData.data.user.activated == 1) {
            // console.log(resData.data.user.activated);
            this.router.navigate(['/dashboard']);
          } else if (+resData.data.user.activated == 0) {
            this.router.navigate(['/auth/verify']);
          } else {
            this.router.navigate(['/auth/verify']);
            // this.router.navigate(['/view/settings']);
          }
        }
      },
      (errorMessage) => {
        this.error = errorMessage;
        this.isLoading = false;
        this.router.navigate(['login']);
      }
    );
    this.verifyForm.reset();
  }
}
