import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '../auth.service';
// import { registerAction } from 'src/app/authentication/store/action';
import { ActivationService } from '../activation.service';

@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss'],
})
export class LoginPageComponent implements OnInit {
  loginForm: FormGroup;
  error: string = '';
  isLoading = false;
  isLoggedOne = false;
  activationMessage: string = '';

  constructor(
    private formBuilder: FormBuilder,
    public router: Router,
    private authService: AuthService,
    private activationService: ActivationService
  ) {}

  ngOnInit(): void {
    this.loginForm = this.formBuilder.group({
      email: ['', Validators.required],
      password: ['', Validators.required],
    });
    this.activationService.currentActivationMessage.subscribe((msg) => {
      this.activationMessage = msg;
    });
  }

  get form() {
    return this.loginForm.controls;
  }

  Submit() {
    this.error = '';
    this.activationMessage = '';
    if (!this.loginForm.valid) {
      return null;
    }
    const email = this.loginForm.controls['email'].value;
    const password = this.loginForm.controls['password'].value;
    this.isLoading = true;
    this.authService.login(email, password).subscribe(
      (resData) => {
        this.isLoading = false;
        this.isLoggedOne = true;
      },
      (errorMessage) => {
        this.error = errorMessage;
        this.isLoading = false;
        this.router.navigate(['login']);
      }
    );
    this.loginForm.reset();
  }
  ngOnDetroy() {}
}
