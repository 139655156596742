import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import PerfectScrollbar from 'perfect-scrollbar';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/authentication/auth.service';
import { NavService } from '../../services/nav.service';
import { SidebarRightService } from '../../services/sidebar-right.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, AfterViewInit {
  layoutSubscription: Subscription;
  public isCollapsed = true;
  isAuthenticated = false;
  userData = {
    first_name: '',
    last_name: '',
    email: '',
  };
  private userSub: Subscription;
  categories = [
    { id: 1, name: 'IT Projects' },
    { id: 2, name: 'Business Case' },
    { id: 3, name: 'Microsoft Project' },
    { id: 4, name: 'Risk Management' },
    { id: 5, name: 'Team Building' },
  ];
  constructor(
    private sidebarRightservice: SidebarRightService,
    public navServices: NavService,
    private authService: AuthService,
    private router: Router
  ) {
    this.layoutSubscription = sidebarRightservice.changeEmitted.subscribe(
      (direction) => {
        const dir = direction.direction;
      }
    );
  }

  ngOnInit(): void {
    this.userSub = this.authService.user.subscribe((user) => {
      this.isAuthenticated = !!user;
      this.userData.first_name = user?.first_name;
      this.userData.last_name = user?.last_name;
      this.userData.email = user?.email;
    });
  }
  toggleSidebarNotification() {
    this.sidebarRightservice.emitSidebarNotifyChange(true);
  }

  toggleSidebarOpen() {
    if ((this.navServices.collapseSidebar = true)) {
      document.querySelector('.main-body').classList.add('sidenav-toggled');
    }
  }
  toggleSidebarClose() {
    if ((this.navServices.collapseSidebar = true)) {
      document.querySelector('.main-body').classList.remove('sidenav-toggled');
    }
  }

  ngAfterViewInit() {
    const sidemenu = document.querySelector('.app-sidebar');
    let ps = new PerfectScrollbar(sidemenu, { wheelPropagation: false });

    const chat = document.querySelectorAll('.chat-scroll');
    let ps1 = new PerfectScrollbar(chat[0], { wheelPropagation: false });
    let ps2 = new PerfectScrollbar(chat[1], { wheelPropagation: false });

    const notification = document.querySelectorAll('.Notification-scroll');
    let ps3 = new PerfectScrollbar(notification[0], {
      wheelPropagation: false,
    });
    let ps4 = new PerfectScrollbar(notification[1], {
      wheelPropagation: false,
    });
  }
  ngOnDestroy() {
    this.userSub.unsubscribe();
  }
  onLogOut() {
    this.authService.logout();
    this.router.navigate(['/login']);
  }
}
