<div class="page">
  <!-- main-signin-wrapper -->
  <div class="my-auto page page-h">
    <div class="main-signin-wrapper">
      <div class="main-card-signin reset-password d-md-flex wd-100p">
        <div
          class="wd-md-50p page-signin-style p-md-5 p-4 text-white d-none d-md-block"
        >
          <div class="my-auto authentication-pages">
            <div>
              <img
                src="../../assets/img/brand/iofs-logo.png"
                class="m-0 mb-4"
                alt="logo"
              />
              <h5 class="mb-4">First Stock Trading Service</h5>
              <p class="mb-5">
                Our service offerings ranges from trade executions, cscs
                creation, stocks analysis, portfolio management, economic/
                financial analysis and advisory.
              </p>
              <a
              href="https://tradingservicewebsite-dev.azurewebsites.net/"
              target="_blank"
              class="btn btn-danger"
              >Learn More</a
              >
            </div>
          </div>
        </div>
        <div class="sign-up-body wd-md-50p">
          <div class="main-signin-header">
            <div class="">
              <h2>Welcome back!</h2>
              <h4 class="text-left">Reset Your Password</h4>
              <div *ngIf="errors">
                <p *ngFor="let error of errors">
                  <span class="text-danger"> {{ error }}</span>
                </p>
              </div>
              <div class="alert alert-success" role="alert" *ngIf="message">
                {{ message }}
              </div>
              <div *ngIf="isLoading" class="text-center">
                <app-loader-spinner></app-loader-spinner>
              </div>
              <form [formGroup]="form" (ngSubmit)="reset()" *ngIf="!isLoading">
                <div class="form-group text-left">
                  <label>New Password</label>
                  <input
                    class="form-control"
                    placeholder="Enter your password"
                    type="password"
                    formControlName="password"
                    required
                  />
                </div>
                <div class="form-group text-left">
                  <label>Confirm Password</label>
                  <input
                    class="form-control"
                    placeholder="Retype Password"
                    type="password"
                    formControlName="password_confirmation"
                    required
                  />
                </div>
                <button
                  class="btn ripple btn-main-primary btn-block"
                  type="submit"
                  [disabled]="!form.valid"
                >
                  Reset Password
                </button>
              </form>
            </div>
          </div>
          <div class="main-signup-footer mg-t-10">
            <p>
              Already have an account?
              <a routerLink="/login">Sign In</a>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /main-signin-wrapper -->
</div>
