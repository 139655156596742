import {
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import PerfectScrollbar from 'perfect-scrollbar';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/authentication/auth.service';
import { DataStorageService } from '../../services/data-storage.service';
import { SidebarRightService } from '../../services/sidebar-right.service';

@Component({
  selector: 'app-notification-sidebar',
  templateUrl: './notification-sidebar.component.html',
  styleUrls: ['./notification-sidebar.component.scss'],
})
export class NotificationSidebarComponent implements OnInit {
  layoutSub;

  @ViewChild('sidebar', { static: false }) sidebar: ElementRef;
  private userSub: Subscription;
  isAuthenticated = false;
  test: string = null;

  constructor(
    private elRef: ElementRef,
    private renderer: Renderer2,
    private sidebarRightService: SidebarRightService,
    private authService: AuthService,
    private router: Router,
    private dataStorage: DataStorageService
  ) {
    this.layoutSub = sidebarRightService.SidebarNotifyChangeEmitted.subscribe(
      (value) => {
        if (
          document.querySelector('.sidebar').classList.value ==
          'sidebar sidebar-right sidebar-animate ps ps--active-y '
        ) {
          this.renderer.removeClass(this.sidebar.nativeElement, 'sidebar-open');
        } else {
          this.renderer.addClass(this.sidebar.nativeElement, 'sidebar-open');
        }
      }
    );
  }

  ngOnInit(): void {
    this.userSub = this.authService.user.subscribe((user) => {
      this.isAuthenticated = !!user;
    });
  }

  ngOnDestroy() {
    if (this.layoutSub) {
      this.layoutSub.unsubscribe();
    }
    if (this.userSub) {
      this.userSub.unsubscribe();
    }
  }

  onClose() {
    this.renderer.removeClass(this.sidebar.nativeElement, 'sidebar-open');
  }
  ngAfterViewInit() {
    const sidebar = document.querySelector('.sidebar-right');
    let ps = new PerfectScrollbar(sidebar, { wheelPropagation: false });
  }
  onLogOut() {
    this.authService.logout();
    this.router.navigate(['/login']);
  }
  fetchCustomer() {
    // this.dataStorage.fetchCustomers().subscribe((customer) => {
    //   console.log(customer);
    // });
  }
}
