import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http';
import { CurrentUserInterface } from 'src/app/shared/types/currentUser.interface';
import { BehaviorSubject, Subject, throwError } from 'rxjs';
import { UserModel } from 'src/app/authentication/user.model';
import { catchError, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
interface AuthResponseData {}

@Injectable({ providedIn: 'root' })
export class AuthService {
  user = new BehaviorSubject<UserModel>(null);
  private tokenExpirationTimer: any;
  constructor(private http: HttpClient, private router: Router) {}
  logout() {
    this.user.next(null);
    this.router.navigate(['login']);
    localStorage.clear();
    if (this.tokenExpirationTimer) {
      clearTimeout(this.tokenExpirationTimer);
    }
    this.tokenExpirationTimer = null;
  }

  signup(
    firstname: string,
    lastname: string,
    email: string,
    companycode: string,
    companyname: string,
    companyemail: string,
    password: string
  ) {
    return this.http.post<CurrentUserInterface>(
      environment.baseApiUrl + 'user',
      {
        first_name: firstname,
        last_name: lastname,
        email: email,
        company_code: companycode,
        company_name: companyname,
        company_email: companyemail,
        password: password,
      }
    );
    // .pipe(
    //   catchError(this.handleError),
    //   tap((resData) => {
    //     this.handleAuthentication(
    //       resData.data.user.email,
    //       resData.data.user.first_name,
    //       resData.data.user.last_name,
    //       resData.data.user.company_name,
    //       resData.data.user.company_email,
    //       resData.data.user.id,
    //       resData.data.token
    //     );
    //   })
    // );
  }
  login(email: string, password: string) {
    return this.http
      .post<CurrentUserInterface>(environment.baseApiUrl + 'user/login', {
        email: email,
        password: password,
      })
      .pipe(
        catchError(this.handleError),
        tap((resData) => {
          this.handleAuthentication(
            resData.data.user.email,
            resData.data.user.first_name,
            resData.data.user.last_name,
            // resData.data.user.company_code,
            resData.data.user.company_name,
            resData.data.user.company_email,
            resData.data.user.id,
            resData.data.token,
            resData.data.user.is_live
          );
        })
      );
  }
  verify_otp(otp: string) {
    const userData = JSON.parse(localStorage.getItem('userData'));
    const header = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${userData._token}`,
    });
    const httpOptions = {
      headers: header,
    };
    return this.http
      .post<CurrentUserInterface>(
        environment.baseApiUrl + 'admin/otp/verify',
        {
          otp: otp,
        },
        httpOptions
      )
      .pipe(
        catchError(this.handleError),
        tap((resData) => {
          this.handleAuthentication(
            resData.data.user.email,
            resData.data.user.first_name,
            resData.data.user.last_name,
            // resData.data.user.company_code,
            resData.data.user.company_name,
            resData.data.user.company_email,
            resData.data.user.id,
            resData.data.token,
            resData.data.user.is_live
          );
        })
      );
  }
  private handleError(errorRes: HttpErrorResponse) {
    let errorMessage = ' Unknown error occured, try again later';
    if (!errorRes.error.status) {
      return throwError(errorMessage);
    }
    errorMessage = errorRes.error.message;
    return throwError(errorMessage);
  }
  private handleAuthentication(
    email: string,
    first_name: string,
    last_name: string,
    // company_code: string,
    company_name: string,
    company_email: string,
    id: string,
    token: string,
    is_live: boolean
  ) {
    const user = new UserModel(
      email,
      first_name,
      last_name,
      // company_code,
      company_name,
      company_email,
      id,
      token,
      is_live
    );
    this.user.next(user);
    this.autoLogout(360000 * 1000);
    localStorage.setItem('userData', JSON.stringify(user));
  }
  authLogin() {
    const userData = JSON.parse(localStorage.getItem('userData'));
    if (!userData) {
      return;
    }
    const loadUser = new UserModel(
      userData.email,
      userData.first_name,
      userData.last_name,
      // userData.company_code,
      userData.company_name,
      userData.company_email,
      userData.id,
      userData._token,
      userData.is_live
    );
    if (loadUser.token) {
      this.user.next(loadUser);
      this.autoLogout(360000);
    }
  }
  autoLogout(expirationDuration: number) {
    this.tokenExpirationTimer = setTimeout(() => {
      this.logout();
    }, expirationDuration);
  }
  verifyUser(url: string) {
    return this.http.get<any>(environment.baseApiUrl + 'verify?' + url);
  }
}
